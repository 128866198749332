import React, { useEffect, useRef, useState } from 'react';
import { Box, HStack, Text, Image } from '@chakra-ui/react';
import { useArtistQueryAll } from '../api/queries/useArtistQuery';
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { useNavigate } from 'react-router-dom';
import _ from "lodash"
import saveData from '../../utils/saveData';
import { getArtistId, sleep } from '../../utils/etcUtils';
import { onElementVisible } from '../../utils/UIUtils';
import { t } from 'i18next';

const ArtistIconSelector = ({ onSelected, onAddCallbackUrl, enableAddCard=true }) => {
  const ref = useRef();
  const [selectedArtist, setSelectedArtist] = useState(null);
  const artists = useArtistQueryAll();

  const selectInitialArtist = () => {
    const initialArtistId = getArtistId();
    if (initialArtistId) {
      // 초기 값이 있을 경우
      const currentArtist =
        _.find(
          artists,
          item => !!item.likedDate && item.artistId === initialArtistId);

      if (currentArtist) {
        setSelectedArtist(currentArtist);
        onSelected?.(currentArtist);
      }
    }
  };

  // #region 컴포넌트가 화면에 보여졌을때를 감지해서 선택된 엘리먼트로 스크롤링한다.
  const selectedArtistToCenter = (artistId) => {
    const selectedElement = document.getElementById(`artistIcon_${artistId}`);
    if (selectedElement) {
      selectedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }

  useEffect(() => {
    return onElementVisible(ref, () => {
      async function scroll() {
        await sleep(100);
        selectedArtistToCenter(getArtistId());
      }
      scroll();

      if (selectedArtist) {
        onSelected?.(selectedArtist);
      } else {
        selectInitialArtist();
      }
    });
  }, []);
  // #endregion

  useEffect(() => {
    if (!!selectedArtist) return;
    if ((artists?.length ?? 0) == 0) return;

    selectInitialArtist();
  }, [artists])

  const handleArtistSelect = (artist) => {
    saveData("artist", artist.artistId);
    setSelectedArtist(artist);
    onSelected?.(artist);
    selectedArtistToCenter(artist.artistId);
  };

  return (
    <Box ref={ref}>
      <HStack spacing={"9px"} px={"20px"} overflow={"hidden"}>
        {artists
          ?.filter((artist) => !!(artist.likedDate))
          .map((artist) => (
          <ArtistCard
            key={artist.artistId}
            artist={artist}
            selected={artist.artistId === selectedArtist?.artistId}
            onSelect={(_) => handleArtistSelect(artist)}
          />
        ))}
        {
          artists
            && _.filter(artists, (a) => !!(a.likedDate)).length < artists.length
            && (enableAddCard && <AddCard onAddCallbackUrl={onAddCallbackUrl} />)
        }
        <div style={{
          width: '200px', /* 또는 원하는 공간의 크기 */
          flexShrink: '0' /* Flex 컨테이너 내에서 이 요소가 축소되지 않도록 함 */
        }}/>
      </HStack>
    </Box>
  );
};

const ArtistCard = ({ artist, selected, onSelect }) => {
  const [applyAnimation, setApplyAnimation] = useState(false);
  const getClassName = () => {
    if (!applyAnimation) return selected ? 'no-animation' : 'fade-out';
    return selected ? 'fade-in' : 'fade-out';
  }
  const onClick = (e) => {
    if (!applyAnimation) setApplyAnimation(true);
    onSelect?.(e);
  }
  return (
    <Box
      as="button"
      onClick={onClick}
      style={{ position: 'relative' }}
      width="64px" height="108px"
      id={`artistIcon_${artist.artistId}`}
    >
      <div className={getClassName()} style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}>
        <svg width="66" height="66">
          {
            <circle cx="33" cy="33" r="28" fill='none' strokeWidth='1.5' stroke='#5C3DFA'/>
          }
        </svg>
      </div>

      <Box width="64px" height="108px" mx={"6px"} mt={"6px"}>
        <Image
          style={{
            paddingLeft: "2px",
            paddingTop: "2px",
            width: "52px",
            height: "52px",
            borderRadius: "50%",
            objectFit: "cover",
            border: '1px solid',
            borderColor: 'rgba(0, 0, 0, 0.05)'
          }}
          src={artist.thumbnailUrl}
          //thumbnailUrl
          //pictureUrl
        />
        <Text
          fontSize="14px"
          py={"14px"}
          textColor={selected ? "#5C3DFA" : "#4E4E4E"}
          fontWeight={600}
          lineHeight={"16.8px"}
          pr={"10px"}
          noOfLines={2}
          whiteSpace="pre-line"
        >{
          (artist.shortName || artist.name)
        }</Text>
      </Box>
    </Box>
  );
};

const AddCard = ({onAddCallbackUrl}) => {
  const navigate = useNavigate();
  return (
    <Box
      as="button"
      onClick={(_) => {
        if (onAddCallbackUrl) {
          navigate(`/artists?artistId=register_artist&callback=${encodeURIComponent(onAddCallbackUrl)}`);
        } else {
          navigate(`/artists?artistId=register_artist`);
        }
      }}
      width="64px" height="115px"
      style={{
        position: 'relative'
      }}
    >
      <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}>
        <svg width="66" height="7px">
          {/* <circle cx="33" cy="3" r="3" fill='#5C3DFA'/> */}
        </svg>
        <svg width="66" height="66">
          <circle cx="33" cy="33" r="24" fill="none" stroke="#B2A3FF" strokeWidth="2" strokeDasharray="5,5" />
        </svg>
      </div>

      <Box px={"6px"} pt={"3px"}>
        <Box width={"52px"} height={"52px"} p={"15px"}>
          <PlusIcon fill={"#B2A3FF"}/>
        </Box>
        <Text
          fontSize="14px"
          pt={"14px"}
          pb={"10px"}
          textColor={"#4E4E4E"}
          fontWeight={500}
          lineHeight={"16.8px"}
        >{t("manageArtistPage.addLikedArtist")}</Text>
      </Box>
    </Box>
  )
}

export default ArtistIconSelector;
