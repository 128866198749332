import { Box, Center, Grid, GridItem, Image, Stack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import React, { useEffect, useState } from "react";

import { t } from "i18next";
import { first, isEmpty } from "lodash";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import FilledButton from "../components/FilledButton";
import Page from "../components/Page";
import { ReactComponent as LikeShapeIcon } from "../icons/like.shape.svg";
import baseUrl from "../utils/baseUrl";
import getToken from "../utils/getToken";
import { queryGet } from "../utils/queryClient";
import { saveData } from "../utils/saveData";
import { useArtistQueryAll } from '../components/api/queries/useArtistQuery';
import { getArtistIdAnyway, sleep } from '../utils/etcUtils';

export default function SelectArtistsPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // 구 ios앱에서 최초 로그인 후 /artists로 보내는데, isFirst가 true여야하지만, 아무런 값도 넣지 않아서 최초 로그인시 재로그인해야하는 버그 수정용
  const hasNoQueryParams = Array.from(queryParams.keys()).length === 0;

  console.log('hasNoQueryParams', hasNoQueryParams)

  const artistIdQueryParam = queryParams.get("artistId");
  const isFirstParam = (queryParams.get("isFirst")) ?? location.pathname == '/select_initial_artists';
  const callbackParam = queryParams.get('callback') ? decodeURIComponent(queryParams.get('callback')) : undefined;

  const [selectedArtist, setSelectedArtist] = useState(null);

  const artists = useArtistQueryAll();

  useEffect(() => {
    if(artistIdQueryParam) return;
    if(isEmpty(artists)) return;

    const likedArtists = artists?.filter((a) => !!a.likedDate);
    if(likedArtists && !isEmpty(likedArtists)) {
      const artistId = getArtistIdAnyway(first(likedArtists));
      if(artistId) {
        saveData("artist", artistId);
        navigate(`/feed`, { replace: true });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artists, artistIdQueryParam])


  const onArtistSelected = async (artistId) => {
    if(artistId == null) {
      return alert(t("selectArtistPage.errorAlert"))
    }
    // 서버로 요청이 사라지면서 선택 하트가 보이게 하기 위해 약간의 딜레이를 준다.
    await sleep(300);

    navigate(`/artists/${artistId}/members`,{
      state: {
        artistId: artistId,
        firstTime: isFirstParam,
        callbackUrl: callbackParam,
        registerArtist: true
    }})
  }

  return (
    <Page title={t("selectArtistPage.pageName")}>
      <Text fontSize='26px'color={"#444444"} fontWeight={700} lineHeight={"120%"}>
        {t("selectArtistPage.title")}
      </Text>
      {/* <Text fontSize='15px' color={"#888"} mt={2}>
        {t("selectArtistPage.description")}
      </Text> */}
      <Grid templateColumns='repeat(2, 1fr)' paddingTop={"37px"} paddingBottom={"96px"} gap={3}>
        {artists && artists.filter((a) => !a.likedDate).map((artist) =>{
          return (
            <GridItem rowSpan={1} key={artist.name}>
              <Box>
                <Stack>
                  <Center>
                  <Box>
                  <Wrap>
                    <WrapItem position={"relative"}>
                      {
                        // #region 아티스트 아이콘
                      }
                      <Image
                        src={artist.pictureUrl}
                        borderRadius='full'
                        width={"154px"}
                        height={"154px"}
                        pl="4px"
                        background={"#FFF"}
                        alt={artist.name}
                        onClick={() => {
                          setSelectedArtist(artist.artistId);
                          onArtistSelected(artist.artistId);
                        }}
                        loading={"lazy"}
                      />
                      {
                        // #endregion
                      }
                      {
                        // #region 선택시 오버레이
                      }
                      <WrapItem
                        borderRadius='full'
                        width={"154px"}
                        height={"154px"}
                        pl="4px"
                        backgroundColor={"#5C3DFA9A"}
                        zIndex={selectedArtist === artist.artistId ? 999 : -11}
                        style={{position: "absolute", top: 0, left: 0}}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <LikeShapeIcon width={60} height={60} fill="#FFF"/>
                      </WrapItem>
                      {
                        // #endregion
                      }
                    </WrapItem>
                  </Wrap>
                  </Box>
                  </Center>

                  <Text
                    className="text-center"
                    paddingTop="16px"
                    mt="0px!important"
                    fontSize="17px"
                    fontWeight={400}
                    lineHeight="20.4px"
                    color="#444444">
                    { artist.name }
                  </Text>
                </Stack>
              </Box>
            </GridItem>
          )
        })}
      </Grid>
    </Page>
  )
}
