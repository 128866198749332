import { Box, Button, Center, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Spacer, Spinner, Stack, Switch, Text, Wrap } from "@chakra-ui/react";
import { t } from "i18next";
import { first, isEmpty, isUndefined, uniq } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import Page from "../components/Page";
import EventResponse from "../components/api/model/EventResponse";
import upsertCollectedPhotoCards from "../components/api/mutations/upsertCollectedPhotoCards";
import useArtistQuery, { useArtistQueryLikedOnly } from "../components/api/queries/useArtistQuery";
import useCollectedPhotoCardIdsQuery from "../components/api/queries/useCollectedPhotoCardIdsQuery";
import usePhotoCardEventsQuery from "../components/api/queries/usePhotoCardEventsQuery";
import MyPhotoCardBottomSheet from "../components/bottomSheet/MyPhotoCardBottomSheet";
import EmptyCase from "../components/errors/EmptyCase";
import LikedMembersList from "../components/lists/LikedMembersList";
import ArtistTextSelector from "../components/selectors/ArtistTextSelector";
import EmptyPhotoCardEventSkeletonV2 from "../components/skeletons/EmptyPhotoCardEventSkeletonV2";
import PhotoCardsByEventEditor from "../components/virtualized/PhotoCardsByEvent/PhotoCardsByEventEditor";
import PhotoCardsByEventViewer from "../components/virtualized/PhotoCardsByEvent/PhotoCardsByEventViewer";
import LightColors from "../constants/LightColors";
import { ReactComponent as ArrowUpShapeIcon } from "../icons/arrow.up.shape.svg";
import { ReactComponent as ChevronDownIcon } from "../icons/chevron.down.svg";
import { ReactComponent as MoreIcon } from "../icons/more.svg";
import SelectPhotoCardBottomNav from "../layout/SelectPhotoCardBottomNav";
import ScrollRestoration from "../utils/ScrollRestoration";
import { getArtistId } from "../utils/etcUtils";
import { setEvent } from "../utils/ga";
import saveData from "../utils/saveData";
import CommonDialog from "../components/alerts/CommonDialog";

export default function ArtistPhotoCardsPageV2() {

  // #region states & variables
  // 편집모드인지
  const [editable, setEditable] = useState(false);
  const [isOpenExitDialog, setIsOpenExitDialog] = useState(false);

  // 바텀시트 보이는지
  const [isMyPhotoCardsBottomSheetOpen, setIsMyPhotoCardsBottomSheetOpen] = useState(false);
  const [currentMember, setCurrentMember] = useState();

  // Initialize state for paginated data and updated data
  const [meta, setMeta] = useState({});
  const [paginatedEvents, setPaginatedEvents] = useState();
  const [updatedCollectedData, setUpdatedCollectedData] = useState({});
  const [selectedCollectedData, setSelectedCollectedData] = useState({});
  const [isColorModeOnEditable, setIsColorModeOnEditable] = useState(false);
  const [forceRender, setForceRender] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState(null);

  const artistId = getArtistId();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const editable = queryParams.get('editable');
    if(editable === "true"){
      setEditable(true);
    }
  }, [location.search]);
  // #endregion

  // #region API
  const artists = useArtistQueryLikedOnly();
  const artist = useArtistQuery(artistId);
  const collectedPhotoCardIds = useCollectedPhotoCardIdsQuery(artistId, currentMember?.memberId);
  const { data, isFetching, fetchNextPage, hasNextPage } = usePhotoCardEventsQuery(artistId, currentMember?.memberId);
  // #endregion

  ScrollRestoration(window.location.pathname);

  // #region Function to handle updated data set.
  const updatedPhotoCardWithPrevData = (prevData, photoCard) => {
    const memberId = (photoCard.memberId || photoCard.member?.memberId || currentMember?.memberId)
    return {
      ...prevData,
      [memberId]: {
        ...prevData[memberId],
        [photoCard.photoCardId]: {
          ...prevData[memberId]?.[photoCard.photoCardId],
          collectedCount: photoCard.collectedCount,
        },
      },
    };
  }

  const handleUpdatedCollectedPhotoCard = (photoCard) => {
    setUpdatedCollectedData((prevData) => {
      return updatedPhotoCardWithPrevData(prevData, photoCard)
    });
  };

  const handleSelectedCollectedPhotoCard = (photoCard) => {
    setSelectedCollectedData((prevData) => {
      return updatedPhotoCardWithPrevData(prevData, photoCard)
    });
  };

  const collectedCount = useMemo(() => {
    const currentCollectedMemberData = updatedCollectedData[currentMember?.memberId] || {};
    const currentSelectedMemberData = selectedCollectedData[currentMember?.memberId] || {};
    if(isEmpty(currentCollectedMemberData) && isEmpty(currentSelectedMemberData)) {
      return collectedPhotoCardIds?.length || 0;
    }

    const newCollectedPhotoCardIds = Object.entries(currentCollectedMemberData).filter(([_, photoCard]) => {
      return photoCard.collectedCount > 0;
    }).flatMap((entries) => { return entries[0] }) || [];

    const newSelectedPhotoCardIds = Object.entries(currentSelectedMemberData).filter(([_, photoCard]) => {
      return photoCard.collectedCount > 0;
    }).flatMap((entries) => { return entries[0] }) || [];

    const newInsertedIds = uniq(newCollectedPhotoCardIds.concat(collectedPhotoCardIds, newSelectedPhotoCardIds));

    const newUncollectedPhotoCardIds = Object.entries(currentCollectedMemberData).filter(([_, photoCard]) => {
      return photoCard.collectedCount <= 0;
    }).flatMap((entries) => { return entries[0] }) || [];

    const newUnselectedPhotoCardIds = Object.entries(currentSelectedMemberData).filter(([_, photoCard]) => {
      return photoCard.collectedCount <= 0;
    }).flatMap((entries) => { return entries[0] }) || [];

    const newDeletedIds = uniq(newUncollectedPhotoCardIds.concat(newUnselectedPhotoCardIds));

    return newInsertedIds.length - newDeletedIds.length;
  }, [currentMember, collectedPhotoCardIds, updatedCollectedData, selectedCollectedData]);

  // #endregion


  // Function to handle the received paginated data
  const handlePaginatedData = (newPaginatedData) => {
    const updatedEvents = newPaginatedData.flatMap((page) =>
      page.events.map((event) => {
        const newEvent = {
          ...event,
          photoCards: event.photoCards.map((photoCard) => {
            const memberId = photoCard.memberId;

            let newCollectedCount = photoCard.collectedCount;
            if(selectedCollectedData[memberId] && selectedCollectedData[memberId][photoCard.photoCardId]) {
              newCollectedCount = selectedCollectedData[memberId][photoCard.photoCardId]?.collectedCount
            }else if(updatedCollectedData[memberId] && updatedCollectedData[memberId][photoCard.photoCardId]) {
              newCollectedCount = updatedCollectedData[memberId][photoCard.photoCardId]?.collectedCount
            }

            return {
              ...photoCard,
              ...{ collectedCount: newCollectedCount },
            };
          }),
        };
        return new EventResponse(newEvent);
      })
    );

    // Use the updatedEvents as needed
    setPaginatedEvents(updatedEvents);
  };

  // Use useEffect to update paginatedData and updatedData on new data arrival
  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedData(data.pages);
      setMeta(first(data.pages)?.meta);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, updatedCollectedData, selectedCollectedData]);

  // #region events
  const loadMore = () => {
    if(hasNextPage){
      fetchNextPage()
    }
  }

  // 멤버 선택 변경
  const onChangedMember = (member) => {
    setCurrentMember(member);
  }

  // 편집 버튼 클릭
  const onEditButtonClick = () => {
    if(editable) {
      navigate(
        "/photo_cards/report",
        { state: { artist: artist, showBackButton: true, navBarVisible: false } }
      )
    } else {
      setEvent({
        category: 'PhotoCard',
        action: 'Edit Collected PhotoCards',
        label: 'PhotoCardsPage',
      });
      setEditable(true)
    }
  }

  const IgnoreAndExit = () => {
    setSelectedCollectedData({});
    setIsOpenExitDialog(false);
    return setEditable(false);
  }

  // 네비게이션 뒤로가기 눌렀을 경우
  const onHistoryBackClick = () => {
    if(editable){
      const upsertRequest = selectedCollectedData[currentMember?.memberId];
      if(isEmpty(upsertRequest)){
        return IgnoreAndExit();
      }
      return setIsOpenExitDialog(true);
    }
    if(!isEmpty(updatedCollectedData)){
      // API V1 Query keys
      queryClient.invalidateQueries(["photoCardEvents"]);
      queryClient.invalidateQueries(["collectedPhotoCardIds"]);
      // Legacy API Query keys
      queryClient.invalidateQueries(["artistPhotoCards"]);
      queryClient.invalidateQueries(["collectedPhotoCards"]);
      queryClient.invalidateQueries(["userProfile"]);
    }
    window.history.back()
  }

  const onClickPhotoCard = (event, photoCard) => {
    if(editable){
      if(photoCard.collectedCount > 0) {
        handleSelectedCollectedPhotoCard({...photoCard, collectedCount: 0});
      } else {
        handleSelectedCollectedPhotoCard({...photoCard, collectedCount: 1});
      }
    } else {
      navigate(`/photo_cards/${photoCard.photoCardId}`);
    }
  }

  const onCompletedEditable = async () => {
    const upsertRequest = selectedCollectedData[currentMember?.memberId];
    if(isEmpty(upsertRequest)){
      return IgnoreAndExit();
    }

    const response = await upsertCollectedPhotoCards(artistId, upsertRequest);
    response.collectedPhotoCards.forEach(photoCard => {
      handleUpdatedCollectedPhotoCard(photoCard);
    });

    setSelectedCollectedData({});
    setEditable(false);
  }

  const onChangeArtist = (updatedArtist) => {
    saveData("artist", updatedArtist.artistId);
    // queryClient.clear();
    // navigate(`/artists/photo_cards_v2`, { replace: true })
    setForceRender(!forceRender);
  }

  const onColorModeSwitchChanged = () => {
    setIsColorModeOnEditable(!isColorModeOnEditable);
  }
  // #endregion

  // 크기, 위치 등을 맞추기 힘들어서 텍스트만 보이지만 메뉴를 활용한다
  const DropdownTextOnly = ({artist}) => {
    return (
      <Menu>
        <MenuButton
          height={"40px"}
          as={Button}
          colorScheme=""
          color={"black"}
          width={"fit-content"}
          padding={0}
          rightIcon={
            <Box height="40px" display="flex" alignItems="center" justifyContent="center">
              <ChevronDownIcon width={"22px"} height={"22px"} fill={"#666666"} opacity={0}/>
            </Box>
          }>
          <Box height="40px" display="flex" alignItems="center" justifyContent="center">
            <Text fontSize={"26px"} fontWeight={700} color={"#222"}>{artist?.name}</Text>
          </Box>
        </MenuButton>
      </Menu>
    );
  };

  const NavigationMenus = (editable) => {
    if (editable) {
      return (<Text onClick={() => navigate(
        "/photo_cards/report",
        { state: { artist: artist, showBackButton: true, navBarVisible: false } }
      )}>{t("photoCardPage.navigationReport")}</Text>)
    } else {
      return(
        <Menu>
          <MenuButton
            minWidth={"0px"}
            height={"18px"}
            as={IconButton}
            variant=""
            aria-label='Options'
            paddingX={"0px!important"}
            icon={<MoreIcon fill={LightColors.textsublv2} width={"17px"} height={"17px"}/>}
          />
          <MenuList>
            <MenuItem onClick={() =>
              navigate(
                "/photo_cards/report",
                { state: { artist: artist, showBackButton: true, navBarVisible: false } }
              )
            }>
              {t("photoCardPage.navigationReport")}
            </MenuItem>
            <MenuItem onClick={() => saveData("hiddenCollectedPhotoCardStat", false)}>
              {t("photoCardPage.showPhotoCardsOnFeed")}
            </MenuItem>
          </MenuList>
        </Menu>
      )
    }

  }

  return (
    <div>
    <Page title={t("photoCardPage.pageName")}
      showBackButton={true}
      navigationElement={NavigationMenus(editable)}
      onHistoryBackClick={onHistoryBackClick}
    >
      <Stack spacing={0}>
        <Text fontSize='17px' color={"#444444"} fontWeight={500}>
          {t("photoCardPage.listTitle")}
        </Text>
        {
          // #region artist name & selector
        }
        {
          editable
            ? <DropdownTextOnly artist={selectedArtist}/>
            : <ArtistTextSelector
                artists={artists}
                onSelected={setSelectedArtist}
                alignDropdown="left"
                customTextAreaElementMultiple={
                  (artist) =>
                      <MenuButton
                        height={"40px"}
                        as={Button}
                        colorScheme=""
                        color={"black"}
                        width={"fit-content"}
                        padding={0}
                        rightIcon={
                          <Box height="40px" display="flex" alignItems="center" justifyContent="center">
                            <ChevronDownIcon width={"22px"} height={"22px"} fill={"#666666"}/>
                          </Box>
                        }>
                        <Box height="40px" display="flex" alignItems="center" justifyContent="center">
                          <Text fontSize={"26px"} fontWeight={700} color={"#222"}>{artist?.name}</Text>
                        </Box>
                      </MenuButton>
                }
                customTextAreaElementSingle={
                  (artist) => <DropdownTextOnly artist={artist}/>
                }
                customExtraDropdownMenus={[
                  {
                    name: t("photoCardPage.manageArtist"),
                    onClick: () => {
                      navigate('/artists/manage');
                    }
                  }
                ]}
                />
        }
        {
          // #endregion
        }
        {
          // #region create pocaboard & color mode
        }
        {
          editable
            ? <Box
                marginTop={"23px!important"}
                marginBottom={"5px!important"}
                paddingX={"20px"}
                paddingY={"14px"}
                width={"100%"}
                borderRadius={"12px"}
                backgroundColor="#0000000A"
              >
                <Wrap justify={"center"}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Image width="16px" height="16px" src="/assets/icons/color.mode.png" marginRight={"7px"}/>
                    <Text color={"#444444"} fontSize={"14px"} fontWeight={500} lineHeight={"16.8px"}>
                      {t("photoCardPage.showColorMode")}
                    </Text>
                  </Box>
                  <Spacer/>

                  <Switch size='sm' colorScheme="primary" isChecked={isColorModeOnEditable} onChange={()=>onColorModeSwitchChanged()}/>
                </Wrap>
              </Box>
            : <Box
                marginTop={"18px!important"}
                marginBottom={"16px"}
                paddingX={"24px"}
                paddingY={"14px"}
                width={"100%"}
                borderRadius={"12px"}
                backgroundImage={"/assets/backgrounds/banner_bg_holography.png"}
              >
                <Wrap justify={"center"} onClick={()=>setIsMyPhotoCardsBottomSheetOpen(true)}>
                  <Text color={"#222222"} fontSize={"17px"} fontWeight={600}>
                    {t("photoCardPage.bannerTitle")}
                  </Text>
                  <ArrowUpShapeIcon fill="black"/>
                </Wrap>
              </Box>
        }
        {
          // #endregion
        }
      </Stack>
      <Wrap spacing={"8px"} paddingTop="18px" paddingBottom="16px" marginBottom={"96px"}>
        <LikedMembersList artistMembers={artist?.members?.filter((member) => member.isLiked)} onChangeLikedMember={onChangedMember}/>
        {
          isUndefined(paginatedEvents) ?
          <EmptyPhotoCardEventSkeletonV2/> :
          (
            isEmpty(paginatedEvents) ?
            <div style={{width: "100%"}}>
              <EmptyCase emoji={"🥺"} description={t("photoCardPage.emptyPhotoCards")}/>
            </div> :
            <div className="w-full">
              {
                editable
                ? <PhotoCardsByEventEditor
                    events={paginatedEvents}
                    onClickedPhotoCard={(photoCard, e) => onClickPhotoCard(e, photoCard)}
                    endReached={loadMore}
                    enableAll={false}
                    isColorMode={isColorModeOnEditable}
                  />
                : <PhotoCardsByEventViewer
                    events={paginatedEvents}
                    onClickedPhotoCard={(photoCard, e) => onClickPhotoCard(e, photoCard)}
                    endReached={loadMore}
                    enableAll={false}
                  />
              }
              {isFetching && <Center marginTop={"8px"}><Spinner size='sm' color="primary.500" marginRight={"20px"}/></Center>}
            </div>
          )
        }
      </Wrap>
    </Page>

    <SelectPhotoCardBottomNav
      selectedMember={currentMember}
      selectedCardCount={collectedCount}
      totalCardCount={meta?.photoCardsInfo?.totalCount}
      onConfirmedButton={onCompletedEditable}
      setEditable={setEditable}
      editable={editable}
      showEditButton={true}
    />

    <MyPhotoCardBottomSheet
      isMyPhotoCardsBottomSheetOpen={isMyPhotoCardsBottomSheetOpen}
      setIsMyPhotoCardsBottomSheetOpen={setIsMyPhotoCardsBottomSheetOpen}
    />
    {
      isOpenExitDialog && <CommonDialog
        isOpen={isOpenExitDialog}
        onClose={() => setIsOpenExitDialog(false)}
        description={t("photoCardPage.discardAlertDialog")}
        noButtonText={t("no")}
        yesButtonText={t("yes")}
        onClickDialogButton={() => IgnoreAndExit()}
      />
    }
    </div>
  )
}
