import React from "react";

import { Flex, Image, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import uuid from "react-uuid";
import styled from "styled-components";

import colors from "../../constants/colors";

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.textMain};
`

const Description = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.photoCardText};
`

const Badge = styled.div`
  min-width: 73px;
  min-height: 21px;
  padding: 4px 7px;
  border-radius: 30px;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  background: rgba(0, 0, 0, 0.04);
  letter-spacing: 0em;
  text-align: left;
  align-self: center;
`

const CardsWrapper = styled.div`
  margin-top: 12px;
  border: 1px solid rgba(231, 231, 231, 0.50);
  border-radius: 12px;
  padding: 16px;
`

export default function PhotoCardFirstCaptorCard({photoCard, name, imagePath, tweetUrl}) {
  return (
    (photoCard.artistId === "plave") &&
    <CardsWrapper>
      <Flex key={uuid()}>
        <Image width={"34px"} height={"34px"} borderRadius={"full"} src={imagePath}/>
        <VStack alignItems={"flex-start"} alignSelf={"center"} flexGrow={1} paddingLeft={"12px"} spacing={"4px"}>
          <Title>{name}</Title>
          <Description>{tweetUrl}</Description>
        </VStack>
        <Badge>{t("photoCardDetailPage.firstCaptorBadge")}</Badge>
      </Flex>
    </CardsWrapper>
  )
}
