import React, { useEffect, useState } from "react";

import { Badge, Flex, Image, Menu, MenuButton, MenuItem, MenuList, Stack } from "@chakra-ui/react";
import { isEmpty, isUndefined } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Page from "../components/Page";
import MatchedPhotoCardResponse from "../components/api/model/MatchedPhotoCardResponse";
import TradingPhotoCardResponse from "../components/api/model/TradingPhotoCardResponse";
import updateCollectedPhotoCard from "../components/api/mutations/legacy/updateCollectedPhotoCard";
import updateWishedPhotoCard from "../components/api/mutations/legacy/updateWishedPhotoCard";
import useMatchedTradingPhotoCardsQuery from "../components/api/queries/useMatchedTradingPhotoCardsQuery";
import usePhotoCardQuery from "../components/api/queries/usePhotoCardQuery";
import useSalesTradingPhotoCardsQuery from "../components/api/queries/useSalesTradingPhotoCardsQuery";

import PrimaryButton from "../components/buttons/PrimaryButton";
import SecondaryLineButton from "../components/buttons/SecondaryLineButton";

import LikeLineIcon from "../components/icons/LikeLineIcon";
import LikeShapeIcon from "../components/icons/LikeShapeIcon";
import MatchedTradingPhotoCardsHorizontalList from "../components/tradings/MatchedTradingPhotoCardsHorizontalList";
import SalesTradingPhotoCardProfileRowListCard from "../components/tradings/SalesTradingPhotoCardProfileRowListCard";

import { useQueryClient } from "react-query";
import usePostsQuery from "../components/api/queries/usePostsQuery";
import ChangePhotoCardCollectedCountBottomSheet from "../components/bottomSheet/ChangePhotoCardCollectedCountBottomSheet";
import SecondaryButton from "../components/buttons/SecondaryButton";
import FullSizeImageViewer from "../components/chats/ImageViewer";
import PostsList from "../components/community/PostsList";
import GridSkeletons from "../components/skeletons/GridSkeletons";
import PhotoCardFirstCaptorCard from "../components/tradings/PhotoCardFirstCaptorCard";
import colors from "../constants/colors";
import { ellipsis } from "../utils/TextUtil";
import { setEvent } from "../utils/ga";
import { t } from "i18next";
import useUserQuery from "../components/api/queries/useUserQuery";

const NavigationWrapper = styled.div`
  flex-direction: row;
  display: flex;
`

const PhotoCardSection = styled.div`
  padding-top: 12px;
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: row;
  display: flex;
`

const ImageWrapper = styled.div`
  flex: none;
`

const EventName = styled.p`
  color: ${colors.eventText};
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

const PhotoCardName = styled.p`
  color: ${colors.photoCardText};
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

const ButtonWrapper = styled.div`
  flex: none;
  align-self: center;
  min-width: 73px;
  min-height: 39px;
  margin-left: 8px;
`

const SectionWrapper = styled.div`
  padding-top: 32px;
  padding-left: 24px;
  padding-right: 24px;
`

const Title = styled.div`
  color: ${colors.textMain};
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`

const Description = styled.div`
  color: ${colors.textSub};
  padding-top: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
`

const PostListWrapper = styled.div`
  margin-top:12px;
`

const commonMenuStyle = {
  justifyContent: "end",
  color: `${colors.textMain}`,
  fontSize: "14px",
  fontWeight: 600,
}

export default function PhotoCardDetailPage() {
  const params = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);

  const [photoCard, setPhotoCard] = useState();
  const [tradingPhotoCards, setTradingPhotoCards] = useState();
  const [paginatedPosts, setPaginatedPosts] = useState();
  const [matchedTradingPhotoCards, setMatchedTradingPhotoCards] = useState();
  const [showChangeCollectCountBottomSheet, setShowChangeCollectCountBottomSheet] = useState(false);
  const [isImageClicked, setIsImageClicked] = useState(false);

  const photoCardData = usePhotoCardQuery(params.photo_card_id);
  useEffect(() => {
    if (!isEmpty(photoCardData)) {
      setPhotoCard(photoCardData);
    }
  }, [photoCardData]);

  const { data: salesTradingPhotoCardsData } = useSalesTradingPhotoCardsQuery(params.photo_card_id);
  useEffect(() => {
    if (!isEmpty(salesTradingPhotoCardsData)) {
      handlePaginatedSalesTradingPhotoCardData(salesTradingPhotoCardsData.pages);
    }
  }, [salesTradingPhotoCardsData]);

  const handlePaginatedSalesTradingPhotoCardData = (newPaginatedData) => {
    const tradingPhotoCards = newPaginatedData?.flatMap((page) =>
      page.tradingPhotoCards.map((tradingPhotoCard) => new TradingPhotoCardResponse(tradingPhotoCard))
    );
    setTradingPhotoCards(tradingPhotoCards);
  }

  const { data: matchedTradingPhotoCardsData } = useMatchedTradingPhotoCardsQuery(params.photo_card_id);
  useEffect(() => {
    if (!isEmpty(matchedTradingPhotoCardsData)) {
      handlePaginatedMachedTradingPhotoCardData(matchedTradingPhotoCardsData.pages);
    }
  }, [matchedTradingPhotoCardsData]);

  const handlePaginatedMachedTradingPhotoCardData = (newPaginatedData) => {
    const matchedPhotoCards = newPaginatedData?.flatMap((page) =>
      page.matchedPhotoCards.map((tradingPhotoCard) => new MatchedPhotoCardResponse(tradingPhotoCard))
    );
    setMatchedTradingPhotoCards(matchedPhotoCards);
  }

  const user = useUserQuery();
  const countryCode = localStorage.getItem("feedCountryCode");
  const { data: postsData, isFetching, fetchNextPage, hasNextPage } = usePostsQuery({
    country: countryCode|| user?.country,
    photoCardId: params.photo_card_id,
  });

  const handlePaginatedPosts = (paginatedData) => {
    const posts = paginatedData.pages?.flatMap((page) =>
      page.posts.map((tradingPhotoCard) => tradingPhotoCard)
    )
    setPaginatedPosts(posts);
  }

  const loadMorePosts = () => {
    if(!isFetching && hasNextPage) {
      fetchNextPage()
    }
  }

  useEffect(() => {
    if (!isEmpty(postsData)) {
      handlePaginatedPosts(postsData);
      // setMeta(first(data.pages)?.meta);
    }
  }, [postsData]);

  const toggleWishedState = async (isWished, card) => {
    setEvent({
      category: 'PhotoCard',
      action: isWished ? 'UnWished PhotoCard' : 'Wished PhotoCard',
    })
    const response = await updateWishedPhotoCard(isWished, card.artistId, card.memberId, [card.photoCardId]);
    if(response.success) {
      invalidateQueries(card);
      setPhotoCard(prevData => ({
        ...prevData,
        isWished: !isWished,
      }));
    }
  }

  const updateCollectedCount = async (card, collectedCount) => {
    setEvent({
      category: 'PhotoCard',
      action: collectedCount > 0 ? 'Collected PhotoCard' : 'UnCollected PhotoCard',
    })
    const response = await updateCollectedPhotoCard(card.photoCardId, collectedCount);
    if(response.success) {
      invalidateQueries(card);
      setPhotoCard(prevData => ({
        ...prevData,
        collectedCount: collectedCount,
      }));
    }
  }

  const invalidateQueries = (photoCard) => {
    queryClient.invalidateQueries(["photoCardV1", photoCard.photoCardId]);
    queryClient.invalidateQueries(["photoCardEvents", photoCard.artistId, photoCard.memberId]);
  }


  const firstCaptor = (photoCard) => {
    if(!photoCard?.artistId) {
      return(<></>)
    }
    if(photoCard.artistId !== "plave"){
      return(<></>)
    }

    if(photoCard.eventId === "08172fb0-80ee-4540-b2d5-8315913c2aa1") {
      return (
        <SectionWrapper>
          <Title>{t("photoCardDetailPage.firstCaptorTitle")}</Title>
          <PhotoCardFirstCaptorCard
            photoCard={photoCard}
            name={"오동"}
            imagePath={"/assets/profiles/odong.png"}
            tweetUrl={"x.com/zxzxeu"}
          />
        </SectionWrapper>
      )
    } else if (!(["6b013439-77c7-4037-aec8-88dbd497b716", "c8b6abba-2354-4f49-b93c-28631d574d50"].includes(photoCard.eventId))) {
      return (
        <SectionWrapper>
          <Title>{t("photoCardDetailPage.firstCaptorTitle")}</Title>
          <PhotoCardFirstCaptorCard
            photoCard={photoCard}
            name={"🦙배덕🐬"}
            imagePath={"/assets/profiles/baeduk.png"}
            tweetUrl={"twitter.com/eejrl"}
          />
        </SectionWrapper>
      )
    } else {
      return (<></>)
    }
  }


  const navigateHome = () => {
    const isNewTab = currentQueryParams.get("newTabOpen");
    if (isNewTab) {
      window.close();
    } else {
      navigate("/feed", { replace: true })
    }
  }

  const back = () => {
    const isNewTab = currentQueryParams.get("newTabOpen");
    if (isNewTab) {
      window.close();
    } else {
      navigate(-1);
    }
  }

  return (
    <>
      <Page
        title={""}
        onHistoryBackClick={() => back()}
        navigationElement={photoCard && (
          <NavigationWrapper>
            <div onClick={() => toggleWishedState(photoCard.isWished, photoCard)}>
            {
              photoCard.isWished ?
              <LikeShapeIcon width={"24px"} height={"24px"} /> :
              <LikeLineIcon width={"24px"} height={"24px"} />
            }
            </div>
          </NavigationWrapper>
        )}
        noGutter={true}
        noPadding={true}
        style={isImageClicked ? {touchAction: "none"} : {}}
      >
        <PhotoCardSection>
          {
            isEmpty(photoCard) ? (
              <div className="w-full">
                <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={128}/>
              </div>
            ) : (
              <>
                <ImageWrapper>
                  <Image
                    key={photoCard.photoCardId}
                    src={photoCard.pictureUrl}
                    height={"120px"}
                    maxWidth={"75px"}
                    objectFit={"cover"}
                    borderRadius="8px"
                    onClick={() => setIsImageClicked(true)}
                  />
                </ImageWrapper>
                <Stack
                  alignItems={"start"}
                  justify={"center"}
                  paddingLeft={"16px"}
                  className="flex-grow"
                >
                  <EventName>{ellipsis(photoCard.eventName.slice(2), 25)}</EventName>
                  <PhotoCardName>{photoCard.pocaname || photoCard.name}</PhotoCardName>
                  <Badge
                    style={{
                      backgroundColor: "secondary.600",
                      fontSize: "13px",
                      fontWeight: 600,
                      lineHeight: "16px",
                      color: "#5C3DFA",
                      marginTop: "10px",
                      padding: "4px 8px",
                      borderRadius: "4px",
                    }}
                  >
                    {photoCard.memberName}
                  </Badge>
                </Stack>
                <ButtonWrapper>
                  {
                    photoCard.collectedCount > 0 ? (
                      <Menu>
                        <MenuButton width={"100%"}>
                          <SecondaryLineButton text={t("photoCardDetailPage.collected")} number={photoCard.collectedCount}/>
                        </MenuButton>
                        <MenuList minWidth={"fit-content"}>
                          <MenuItem style={commonMenuStyle} onClick={() => updateCollectedCount(photoCard, 0)}>{t("photoCardDetailPage.changeToNotCollected")}</MenuItem>
                          <MenuItem style={commonMenuStyle} onClick={() => setShowChangeCollectCountBottomSheet(true)}>{t("photoCardDetailPage.changeCollectedCount")}</MenuItem>
                          {/* <MenuItem style={commonMenuStyle} onClick={() => navigateTradingPhotoCard(photoCard.artistId)}>{"이 포카 양도하기"}</MenuItem> */}
                        </MenuList>
                      </Menu>
                  ) : (
                      <PrimaryButton
                        display={"flex"}
                        justifyContent={"center"}
                        minWidth={"81px"}
                        minHeight={"39px"}
                        text={t("photoCardDetailPage.notCollected")}
                        onClick={() => updateCollectedCount(photoCard, 1)}
                      />
                    )
                  }
                </ButtonWrapper>
              </>
            )
          }
        </PhotoCardSection>
        {firstCaptor(photoCard)}
        {
          isUndefined(matchedTradingPhotoCards) ? (
            <SectionWrapper>
              <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={6}/>
              <MatchedTradingPhotoCardsHorizontalList matchedTradingPhotoCards={matchedTradingPhotoCards}/>
            </SectionWrapper>

          ) : !isEmpty(matchedTradingPhotoCards) && (
            <SectionWrapper>
              <Title>{t("photoCardDetailPage.exchangeTradingPhotoCardTitle")}</Title>
              <MatchedTradingPhotoCardsHorizontalList matchedTradingPhotoCards={matchedTradingPhotoCards}/>
            </SectionWrapper>
          )
        }
        {
          isUndefined(tradingPhotoCards) ? (
            <SectionWrapper>
              <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={6}/>
              <SalesTradingPhotoCardProfileRowListCard tradingPhotoCards={tradingPhotoCards}/>
            </SectionWrapper>

          ) : photoCard && (
            <SectionWrapper>
              <Title>{t("photoCardDetailPage.saleTradingPhotoCardTitle", {fandom: photoCard.fandomName})}</Title>
              <SalesTradingPhotoCardProfileRowListCard tradingPhotoCards={tradingPhotoCards}/>
            </SectionWrapper>
          )
        }
        <SectionWrapper>
          <Flex>
            <div style={{flexGrow: "1"}}>
              <Title>{t("photoCardDetailPage.feedTitle", {fandom: photoCard?.fandomName})}</Title>
              <Description>{t("photoCardDetailPage.feedDescription")}</Description>
            </div>
            <SecondaryButton fontWeight={700} text={t("photoCardDetailPage.showAllFeed")} onClick={() => navigateHome()}/>
          </Flex>
        </SectionWrapper>
        <PostListWrapper>
          <PostsList user={user} posts={paginatedPosts} loadMore={loadMorePosts} hasMore={hasNextPage}/>
        </PostListWrapper>
      </Page>
      {
        isImageClicked && <FullSizeImageViewer imageUrl={photoCard.pictureUrl} onClosed={() => setIsImageClicked(false)}/>
      }
      {
        photoCard && <ChangePhotoCardCollectedCountBottomSheet
          showChangeCollectCountBottomSheet={showChangeCollectCountBottomSheet}
          setShowChangeCollectCountBottomSheet={setShowChangeCollectCountBottomSheet}
          currentCollectedCount={photoCard.collectedCount}
          updatedCollectedCount={(collectedCount) => updateCollectedCount(photoCard, collectedCount)}
        />
      }

    </>
  )
}
