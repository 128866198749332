import React, { useRef, useState } from "react";

import { isIOS, isMobile } from 'react-device-detect';
import Sheet from 'react-modal-sheet';
import { useFilePicker } from 'use-file-picker';

import { Center, Flex, Image, Input, Spacer, Text, VStack, Wrap, WrapItem, useToast } from '@chakra-ui/react';

import { ReactComponent as CameraIcon } from "../../icons/camera.svg";
import { ReactComponent as EditIcon } from "../../icons/edit.svg";
import { ReactComponent as ProfileFillIcon } from "../../icons/profile.fill.svg";
import PrimaryButton from "../buttons/PrimaryButton";

import { first } from "lodash";
import { useQueryClient } from "react-query";
import colors from "../../constants/colors";
import { isNativeIOS } from "../../utils/NativeUtil";
import { SPRING_CONFIG } from "../../utils/bottomSheetSpringOption";
import addAndMergeUserProfileV1 from "../api/mutations/addAndMergeUserProfileV1";
import { useNavigate } from "react-router-dom";
import ToastMessageBox from "../alerts/ToastMessageBox";
import addUserProfileV1 from "../api/mutations/addUserProfileV1";
import { t } from "i18next";
import { hasBatchim } from "../../utils/TextUtil";
import LightColors from "../../constants/LightColors";


const addProfileButtonStyle = {
  padding: "14px",
  fontWeight: 600,
  fontSize: "17px",
  lineHeight: "20px"
}

const titleStyle = {
  fontWeight: 700,
  fontSize: "17px",
  lineHeight: "20px",
  wordBreak: "keep-all",
  padding: "0px 30px"
}

export default function AddUserProfileV1BottomSheet({
  title,
  userProfile,
  isShowProfileBottomSheet,
  setIsShowProfileBottomSheet,
  isMergeProfile=false,
  category="trading",
  afterGoBack=false,
}){
  const containerRef = useRef();
  const inputRef = useRef();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();

  const [nickname, setNickname] = useState(userProfile?.nickname);
  const [errorMessage, setErrorMessage] = useState("");


  const { openFilePicker, filesContent } = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });

  const buttonText = () => {
    if(!nickname) return t("profiles.defaultButton");
    return hasBatchim(nickname) ? t("profiles.hasBatchimNickNameButton", { nickname: nickname }) : t("profiles.nickNameButton", { nickname: nickname });
  }

  const onFocusedInput = () => {
    if (isNativeIOS()) return;
    if(containerRef.current && isMobile && isIOS){
      containerRef.current.style.paddingBottom = "360px"
    }
  }

  const onUnFocusedInput = () => {
    if (isNativeIOS()) return;
    if(containerRef.current && isMobile && isIOS){
      containerRef.current.style.paddingBottom = "0px"
    }
  }

  const listener = () => {
    const MIN_KEYBOARD_HEIGHT = 300 // N.B.! this might not always be correct
    const isMobile = window.innerWidth < 768
    const isKeyboardOpen = isMobile && isIOS && ((window.screen.height - MIN_KEYBOARD_HEIGHT) > window.visualViewport.height)

    if(!isKeyboardOpen && inputRef.current){
      onUnFocusedInput()
    }
  }
  window.visualViewport.addEventListener('resize', listener)

  const onUpdateNickname = (nickname) => {
    setErrorMessage("")
    setNickname(nickname)
  }

  const createUserProfile = async (nickname) => {
    if (!nickname) {
      return setErrorMessage(t("profiles.emptyNicknameErrorMessage"));
    }
    if(userProfile && !isMergeProfile){
      category = userProfile.category;
    }
    if(isMergeProfile) {
      const response = await addAndMergeUserProfileV1(category, nickname, filesContent[0]?.content);
      if (response.ok) {
        toast({
          duration: 1500,
          render: () => (
            <ToastMessageBox message={t("profiles.mergedNicknameToastMessage")}/>
          ),
        });
        completed();
      }
    } else {
      const response = await addUserProfileV1(category, nickname, filesContent[0]?.content);
      if (response.ok) {
        completed();
      }
    }
  }

  const completed = () => {
    queryClient.invalidateQueries("users");
    queryClient.invalidateQueries("userProfile");
    queryClient.invalidateQueries("V1:UserProfilesList");
    setIsShowProfileBottomSheet(false);
    if (afterGoBack) {
      navigate(-1);
    }
  }

  return (
    <div>
      <Sheet
        isOpen={isShowProfileBottomSheet}
        disableDrag={true}
        onClose={() => {}}
        springConfig={SPRING_CONFIG}
        detent="content-height"
        style={{position: "sticky", zIndex: 5000}}
      >
      <Sheet.Container style={{
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
        position: "sticky",
        paddingBottom: "calc(2rem + env(safe-area-inset-bottom))"
      }}>
        <Sheet.Header className="bottomSheetHeader" style={{paddingTop: "30px"}}>
          <VStack className="text-center" gap={0}>
            <Text style={titleStyle}>{title || t("profiles.title")}</Text>
          </VStack>
        </Sheet.Header>
        <Sheet.Content ref={containerRef} disableDrag={true} style={{paddingLeft: "24px", paddingRight: "24px", position: "sticky"}}>
          <Center>
            <Wrap onClick={openFilePicker}>
              <WrapItem className="relative">
                <Center width={"64px"} height={"64px"} bg="rgba(220, 214, 250, 1)" borderRadius={"32px"}>
                  {
                    filesContent.length > 0 || userProfile?.thumbnailUrl ?
                    <>
                      <Image
                        width={"64px"}
                        height={"64px"}
                        objectFit={"cover"}
                        borderRadius={"full"}
                        src={first(filesContent)?.content || userProfile?.thumbnailUrl}
                      />
                      <div className="absolute bottom-0 right-0" style={{ backgroundColor: "#5C3DFA", borderRadius: "32px", color: "white", padding: "3px" }}>
                        <EditIcon fill="white" width={"10px"} height={"10px"}/>
                      </div>
                    </>
                     :
                    <div>
                      <ProfileFillIcon fill="white" width={"45px"} height={"45px"} />
                      <div className="absolute bottom-0 right-0" style={{ backgroundColor: "#5C3DFA", borderRadius: "32px", color: colors.primary, padding: "4px", borderWidth: "2px", borderColor: "white" }}>
                        <CameraIcon fill={"white"} width={"16px"} height={"16px"}/>
                      </div>
                    </div>
                  }
                </Center>
              </WrapItem>
            </Wrap>
          </Center>
          <div style={{marginTop: "24px"}}>
            <Input
              ref={inputRef}
              variant="filled"
              size={"lg"}
              bgColor={LightColors.secondary}
              _hover={{bgColor: LightColors.secondary}}
              _focusVisible={true}
              placeholder={t("profiles.inputNickNamePlaceholder")}
              maxLength={20}
              value={nickname}
              onFocus={() => onFocusedInput()}
              onBlur={() => onUnFocusedInput()}
              onTouchStart={(e) => setTimeout(() => { onFocusedInput(e) }, 150)}
              onChange={(e) => onUpdateNickname(e.target.value)}
            />
            <Flex>
              <div>
              {(errorMessage) && <Text color={"red"} fontSize={"13px"}>{errorMessage}</Text>}
              </div>
              <Spacer/>
              <div>
              {(nickname) && <Text value={nickname} fontSize={"13px"}>{nickname.length}/20</Text>}
              </div>
            </Flex>

          </div>
          <PrimaryButton
            disabled={!nickname && !errorMessage}
            text={buttonText()}
            marginTop={"12px"}
            style={addProfileButtonStyle}
            onClick={() => createUserProfile(nickname)}
          />
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onClick={() => setIsShowProfileBottomSheet(false)} />
    </Sheet>
    </div>
  )
}
