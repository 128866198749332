import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Center, Stack, Text, FormControl, Input, Spinner,FormErrorMessage } from '@chakra-ui/react';
import { validate } from 'react-email-validator';
import baseUrl from "../utils/baseUrl";
import Page from "../components/Page";
import { t } from "i18next";

async function requestVerificationCode(email, is_promotion_agreed) {
  return fetch(baseUrl() + "/auth/request_verification_code", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      'email': email,
      'locale': navigator.language || navigator.userLanguage,
      "is_promotion_agreed": is_promotion_agreed,
      "is_terms_agreed": true,
    }),
  }).then((data) => data.json());
}

export default function RequestVerificationCodePage() {
    const [input, setInput] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const navigate = useNavigate();
    const location = useLocation();
    const currentQueryParams = new URLSearchParams(location.search);
    const isPromotionAgreed = currentQueryParams.get("isPromotionAgreed") === "true";

    const handleInputChange = (e) => setInput(e.target.value)
    const isError = input && (!validate(input) || input.endsWith(".con") || input.endsWith("gamil.com") || (input.replace(/\s/g, '') !== input))

    const postEmail = async () => {
      if(isError) return alert(t('requestVerificationCodePage.emailErrorMessage'))
      setIsLoading(true)
      const response = await requestVerificationCode(input, isPromotionAgreed);
      setIsLoading(false)
      if(response && response.success) {
        navigate("/login_with_code", {
          state: {
            email: response.email,
        }})
      } else {
        setErrorMessage(response.errorMessage)
      }
    }

    return (
      <Page title={""}>
        <Stack spacing={0}>
          <Text className="whitespace-pre-line" fontSize='2xl' fontWeight={700} lineHeight={"120%"}>{t('requestVerificationCodePage.title')}</Text>
        </Stack>
        <Text className="whitespace-pre-line" fontSize='15px' color={"#888"} mt={2}>{t('requestVerificationCodePage.description')}</Text>
        <FormControl isInvalid={isError} mt={"44px"}>
          <Input
            type='email'
            borderRadius={"12px"}
            bgColor={"rgba(92,61,250,0.06)"}
            value={input}
            onChange={handleInputChange}
            variant="filled"
            paddingY={6}
            _hover={{bgColor:"rgba(92,61,250,0.06)"}}
            placeholder={t('requestVerificationCodePage.placeHolder')}
          />
          {!isError ? (
              (input === "") ?
              <></>
              :
              <></>
          ) : (
            (errorMessage !== null && errorMessage)
            ? <FormErrorMessage fontSize={"13px"}>{errorMessage}</FormErrorMessage>
            : <FormErrorMessage fontSize={"13px"}>{t('requestVerificationCodePage.emailErrorMessage')}</FormErrorMessage>
          )}
        </FormControl>
        <Center mt={10}>
          <Box as="button"
            fontWeight={700}
            paddingY={"16px"}
            disabled={input === ""}
            borderRadius={56}
            minWidth={"335px"}
            minHeight={"52px"}
            onClick={postEmail}
            bgColor={input !== "" ? "primary.500" : "#E7E7E7"}
            color={input !== "" ? "white" : "#B9B9B9"}
            className="text-white bottomButton"
          >
            {isLoading ? <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='sm'
            /> :  <>{t('next')}</>}
          </Box>
        </Center>
      </Page>
    )


}
