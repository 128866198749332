import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function addTradingPhotoCards(userProfileId, tradingPhotoCards) {
  return fetch(baseUrl() + `/api/v1/trading_photo_cards/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      trading_photo_cards: tradingPhotoCards.map((t) => {
        return {
          artist_id: t.artistId,
          member_id: t.photoCard.member.memberId,
          user_profile_id: userProfileId,
          photo_card_id: t.photoCard.photoCardId,
          quantity: 1,
          category: t.category || "exchange",
          price: t.price,
        }
      }),
    }),
  });
}
