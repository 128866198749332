import { useInfiniteQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";
import { first } from "lodash";

const DEFAULT_PAGE = 15;

export default function useChatRoomsQuery(tradingPhotoCardId, onlyJoined=false, perPage) {
  async function fetchChatRoomsQuery({ pageParam = 1 }) {
    const queryParams = new URLSearchParams({
      per_page: perPage || DEFAULT_PAGE,
      page: pageParam,
    });

    if (onlyJoined) {
      queryParams.append('only_joined', onlyJoined);
    }

    if (tradingPhotoCardId) {
      queryParams.append('trading_photo_card_id', tradingPhotoCardId);
    }

    const url = `/api/v1/chat_rooms?${queryParams.toString()}`;
    try {
      const data = await queryGet(url);
      return data;
    } catch (error) {
      throw new Error("Error fetching paginated data.");
    }
  }

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["ChatRooms", tradingPhotoCardId],
    fetchChatRoomsQuery,
    {
      getNextPageParam: (lastPage) => {
        if (!!lastPage?.meta?.pagination?.nextPage) {
          return lastPage?.meta?.pagination?.nextPage;
        } else {
          return undefined
        }
      }
    }
  );

  return {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  };
}


export async function fetchRecentChatRoomQuery({ tradingPhotoCardId }) {
  const url = `/api/v1/chat_rooms?trading_photo_card_id=${tradingPhotoCardId}&page=1&per_page=1`;
  try {
    const data = await queryGet(url);
    return first(data.chatRooms);
  } catch (error) {
    throw new Error("Error fetching paginated data.");
  }
}
