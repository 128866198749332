import React, { useEffect, useState } from "react";

import { Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerOverlay, Radio } from "@chakra-ui/react";
import { t } from "i18next";
import styled from "styled-components";

import { FindCountyByCountryCode, GLOBAL_COUNTRY, IsSupportedCountry } from "../../../constants/Countries";
import LightColors from "../../../constants/LightColors";

const Row = styled.div`
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  flex-direction: row;
`

const TextWrapper = styled.div`
  flex-grow: 1;
`

const EnabledTitle = styled.div`
  color: ${LightColors.textmainlv1};
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`

const EnabledDescription = styled.div`
  color: ${LightColors.textsublv1};
  padding-top: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
`

const DisabledTitle = styled.div`
  color: ${LightColors.textsublv2};
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`

const DisabledDescription = styled.div`
  color: ${LightColors.disable};
  padding-top: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
`

const RadioWrapper = styled.div`
  align-content: center;
  padding-right: 12px;
`

export default function SelectFeedCountryBottomDrawer({isOpen, onClose, focusRef, defaultCountryCode, onConfirmedCountryCode}) {
  const [selectedCountryCode, setSelectedCountryCode] = useState(defaultCountryCode);
  const currentCountry = FindCountyByCountryCode(selectedCountryCode);
  const feedCountryCode = localStorage.getItem("feedCountryCode")

  useEffect(() => {
    setSelectedCountryCode(FindCountyByCountryCode(defaultCountryCode)?.code || GLOBAL_COUNTRY.code)
  }, [defaultCountryCode]);

  const isGlobalChecked = () => {
    return (feedCountryCode === GLOBAL_COUNTRY.code || GLOBAL_COUNTRY.code === selectedCountryCode)
  }

  const onSelectCountryCode = (countryCode) => {
    setSelectedCountryCode(countryCode);
    onConfirmedCountryCode(countryCode);
    onClose();
  }

  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement='bottom'
        finalFocusRef={focusRef}
      >
        <DrawerOverlay/>
        <DrawerContent
          motionProps={{
            variants: {
              enter: {
                y: "0%",
                transition: { duration: 0.15 },
              },
              exit: {
                y: "100%",
                transition: { duration: 0.1 },
              },
            }
          }}
          style={{
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px"
          }}
        >
          <DrawerBody paddingX={"20px"} maxHeight={"320px"}>
            {
              IsSupportedCountry(selectedCountryCode) ?
              <>
                <Row key={GLOBAL_COUNTRY.code} onClick={() => onSelectCountryCode(GLOBAL_COUNTRY.code)}>
                  <RadioWrapper>
                    <Radio colorScheme="primary" isChecked={isGlobalChecked()}/>
                  </RadioWrapper>
                  {GlobalFeedRowTextWrapper(currentCountry)}
                </Row>
                <Row key={currentCountry.code} onClick={() => onSelectCountryCode(currentCountry.code)}>
                  <RadioWrapper>
                    <Radio colorScheme="primary" isChecked={!isGlobalChecked()}/>
                  </RadioWrapper>
                  <TextWrapper>
                    <EnabledTitle>
                      {t("changeCountry.supportedCountyTitle", {country: currentCountry.name})}
                    </EnabledTitle>
                    <EnabledDescription>
                      {t("changeCountry.supportedCountyDescription", {country: currentCountry.name})}
                    </EnabledDescription>
                  </TextWrapper>
                </Row>

              </> :
              <>
                <Row key={GLOBAL_COUNTRY.code} onClick={() => onClose()}>
                  <RadioWrapper>
                    <Radio colorScheme="primary" isChecked={true}/>
                  </RadioWrapper>
                  {GlobalFeedRowTextWrapper(currentCountry)}
                </Row>
                <Row key={"none"}>
                  <RadioWrapper>
                    <Radio colorScheme="primary"/>
                  </RadioWrapper>
                  <TextWrapper>
                    <DisabledTitle>
                      {t("changeCountry.unSupportedCountryTitle", {country: currentCountry.name})}
                    </DisabledTitle>
                    <DisabledDescription>
                      {t("changeCountry.unSupportedCountryDescription", {country: currentCountry.name})}
                    </DisabledDescription>
                  </TextWrapper>
                </Row>
              </>
            }
          </DrawerBody>
          <DrawerFooter className="safeAreaMarginBottom" width={"100%"} paddingTop={"10px"} paddingBottom={"0px"} paddingX={"20px"}>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

const GlobalFeedRowTextWrapper = (country) => {
  return(
    <TextWrapper>
      <EnabledTitle>
        {t("changeCountry.globalFeedTitle")}
      </EnabledTitle>
      <EnabledDescription>
        {t("changeCountry.globalCountyDescription", {country: country.name})}
      </EnabledDescription>
    </TextWrapper>
  )
}