import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { AlertDialog, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import { ReactComponent as RightArrowIcon } from "../../icons/arrow.right.svg";
import { ReactComponent as LeftArrowIcon } from "../../icons/arrow.left.svg";

const FullImageViewerDialog = ({ isOpen, imageUrl, onClosed, focusRef, onPrevImage, onNextImage }) => {
  const canvasRef = useRef(null);
  useEffect(() => {
    // Save the original viewport content
    const originalViewportContent = document.querySelector('meta[name="viewport"]').getAttribute('content');

    // Change meta tag for viewport scale
    const viewport = document.querySelector('meta[name="viewport"]');
    document.body.style.overflow = "hidden";
    if (viewport) {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=3.0');
    }

    // Cleanup function when the component unmounts
    return () => {
      // Revert the changes to the original viewport content
      const viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        viewport.setAttribute('content', originalViewportContent);
      }
      document.body.style.overflow = "auto";
    };
  }, []);


  return (
      <AlertDialog
        isOpen={isOpen}
        autoFocus={false}
        closeOnOverlayClick={false}
        onClose={onClosed}
        finalFocusRef={focusRef}
        isCentered
      >
      <AlertDialogOverlay zIndex={2147483647}>
        <AlertDialogContent height={"100vh"}>
          <Wrapper>
            <Overlay>
              <CloseWrapper>
                <CloseIcon fill={"white"} onClick={onClosed}/>
              </CloseWrapper>
              {
                onPrevImage &&
                <LeftArrayWrapper>
                  <LeftArrowIcon fill={"white"} onClick={onPrevImage}/>
                </LeftArrayWrapper>
              }
              {
                onNextImage &&
                <RightArrayWrapper>
                  <RightArrowIcon fill={"white"} onClick={onNextImage}/>
                </RightArrayWrapper>
              }
              <FullImage src={imageUrl} alt="Full Size Image" />
              <canvas ref={canvasRef} style={{ display: 'none' }} />
            </Overlay>
          </Wrapper>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

const Wrapper = styled.div`
  position: relative;
  z-index: 2147483646;
  touch-action: auto;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2147483646;
  touch-action: auto;
`;

const FullImage = styled.img`
  max-width: 95%;
  max-height: 95%;
  z-index: 2147483646;
  touch-action: auto;
`;

const CloseWrapper = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  padding-top: 34px;
  z-index: 2147483647;
`;

const RightArrayWrapper = styled.button`
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  padding-top: 34px;
  z-index: 2147483647;
`;

const LeftArrayWrapper = styled.button`
  position: absolute;
  top: 50%;
  left: 20px;
  cursor: pointer;
  padding-top: 34px;
  z-index: 2147483647;
`;

export default FullImageViewerDialog;
