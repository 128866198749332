import React from "react";

import { Text, VStack } from '@chakra-ui/react';

import { t } from "i18next";

const emojiStyle = {
  fontSize: "50px",
}

const titleStyle = {
  fontWeight: 700,
  color: "rgba(68, 68, 68, 1)",
  fontSize: "17px",
  wordBreak: "keep-all",
  lineHeight: "22px"
}

const descriptionStyle = {
  fontWeight: 500,
  color: "rgba(136, 136, 136, 1)",
  fontSize: "14px",
  lineHeight: "17px"
}

export default function EmptyExploreTradingPhotoCard() {
  return (
    <VStack marginTop={"128px"} alignContent={"center"}>
      <Text style={emojiStyle}>🙄</Text>
      <Text style={titleStyle}>
        {t("emptyExploreTradingPhotoCard.title")}
      </Text>
      <Text style={descriptionStyle}>
        {t("emptyExploreTradingPhotoCard.description")}
      </Text>
    </VStack>
  )
}