import React, { useRef, useState } from "react";

import { Input, InputGroup, SimpleGrid, Stack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { isEmpty, isUndefined, sortBy } from "lodash";
import Sheet from 'react-modal-sheet';
import { useQuery } from "react-query";

import { ReactComponent as CloseIcon } from "../../../icons/xmark.svg";
import { queryGet } from "../../../utils/queryClient";

import { Virtuoso } from "react-virtuoso";
import LightColors from "../../../constants/LightColors";
import filterPhotoCards from "../../../utils/SearchPhotoCard";
import removedEmojiEventName from "../../../utils/TextUtil";
import { SPRING_CONFIG } from "../../../utils/bottomSheetSpringOption";
import LikedMembersList from "../../lists/LikedMembersList";
import TradingPhotoCardWindowList from "../../lists/TradingPhotoCardWindowList";
import AddTradingPhotoCard from "../../photocard/AddTradingPhotoCard";
import ArtistIconSelector from "../../selectors/ArtistIconSelector";
import EmptyPhotoCardEventSkeletonV2 from "../../skeletons/EmptyPhotoCardEventSkeletonV2";
import { t } from "i18next";

export default function AddPhotoCardBottomSheet({
  title,
  isCheckPhotoCardBottomSheetOpen,
  setIsCheckPhotoCardBottomSheetOpen,
  onConfirmedPhotoCards,
}){
  const containerRef = useRef();
  const headerRef = useRef();

  const [selectedArtist, setSelectedArtist] = useState();
  const [selectedMember, setSelectedMember] = useState();
  const [searchText, setSearchText] = useState();

  const artistId = selectedArtist?.artistId;

  const convertPhotoCardWithEvent = (photoCard, e) => {
    return {...photoCard, ...{ event: e.event }}
  }

  const { data: allPhotoCardByEvent } = useQuery(
    [`allArtistPhotoCards`, artistId],
    () => queryGet(`/artists/${artistId}/photo_cards?page=1&per_page=999999`),
    {
      enabled: !!artistId,
      select: (r) => r.events.flatMap((e) => e.photoCards.map((p) => convertPhotoCardWithEvent(p, e)))
    },
  );

  const { data: photoCardByEvent } = useQuery(
    ["artistPhotoCards", artistId, selectedMember?.memberId],
    () => queryGet(`/artists/${artistId}/members/${selectedMember?.memberId}/photo_cards?page=1&per_page=99999`),
    {
      enabled: !!artistId && !!selectedMember?.memberId,
    },
  );

  const selectPhotoCard = (photoCard, e) => {
    onConfirmedPhotoCards(photoCard);
  }

  const onSelectedArtist = (artist) => {
    setSelectedArtist(artist);
  }

  const onSelectedMember = (member) => {
    setSelectedMember(member)
  }

  const onHandleSearchText = (text) => {
    setSearchText(text)
  }

  const scrollHeight = () => {
    if (containerRef?.current?.offsetHeight && headerRef?.current?.offsetHeight) {
      return containerRef?.current?.offsetHeight - headerRef?.current?.offsetHeight - 24;
    } else {
      return "60vh"
    }
  }

  return (
    <Sheet
      isOpen={isCheckPhotoCardBottomSheetOpen}
      onClose={() => setIsCheckPhotoCardBottomSheetOpen(false)}
      springConfig={SPRING_CONFIG}
      style={{ zIndex: 2147483647 }}
    >
      <Sheet.Container
        ref={containerRef}
        className="pb-8"
        style={{borderTopLeftRadius: "20px", borderTopRightRadius: "20px"}}
      >
        <Sheet.Header ref={headerRef}>
          <SimpleGrid columns={3} alignItems="center" margin={"16px"}>
            <CloseIcon onClick={() => setIsCheckPhotoCardBottomSheetOpen(false)} fill={"black"}/>
            <Text fontWeight={700} fontSize={"17px"} lineHeight={"20px"} className="text-center">{title}</Text>
            <div></div>
          </SimpleGrid>

          <ArtistIconSelector
            onSelected={onSelectedArtist}
            enableAddCard={false}
          />
          <div style={{paddingTop: "4px", paddingLeft: "24px", paddingRight: "24px"}}>
            <LikedMembersList
              artistMembers={selectedArtist?.members}
              onChangeLikedMember={onSelectedMember}
              showAllMember={true}
            />
          </div>

          <InputGroup marginTop="12px" paddingX={"24px"}>
            <Input
              height={"45px"}
              border={"none"}
              borderRadius={"12px"}
              fontSize={"14px"}
              lineHeight={"17px"}
              background={LightColors.surface}
              color={LightColors.textmainlv2}
              fontWeight={400}
              placeholder={t("addPhotoCard.searchPlaceholder")}
              _placeholder={{color: LightColors.onDisable}}
              onChange={(e)=> onHandleSearchText(e.target.value)}
              width={"calc(100% + 12px)"}
            />
          </InputGroup>
          <Text
            color={LightColors.textsublv1}
            fontSize={"13px"}
            paddingX={"24px"}
            paddingTop={"8px"}
            paddingBottom={"24px"}
            textAlign={"left"}
          >
            {t("addPhotoCard.description")}
          </Text>
        </Sheet.Header>

        <Sheet.Content
          disableDrag={true}
          style={{paddingLeft: "24px", paddingRight: "24px", overflowY: "auto"}}
        >
          {
            (!isEmpty(searchText) && !isEmpty(allPhotoCardByEvent)) ?
            <TradingPhotoCardWindowList
              tradingPhotoCards={filterPhotoCards(allPhotoCardByEvent, searchText)}
              addTradingPhotoCard={selectPhotoCard}
              windowHeight={scrollHeight()}
            /> :
            (
              (isUndefined(photoCardByEvent))
              ? <EmptyPhotoCardEventSkeletonV2/> :
              (
                !isEmpty(photoCardByEvent) &&
                <Virtuoso
                  style={{ height: scrollHeight()}}
                  overscan={480}
                  data={photoCardByEvent?.events}
                  itemContent={(_, e) =>
                    {
                      const photoCards = e.photoCards.map((p) => convertPhotoCardWithEvent(p, e));
                      return photoCards.length > 0 && (
                        <Stack key={e.event?.name} paddingBottom={"18px"}>
                          <Wrap paddingTop={"10px"} paddingBottom={"4px"} align={"center"} marginLeft={"-1px"} spacing={0}>
                            <WrapItem alignItems={"center"}>
                              <Text fontSize={"20px"} marginRight={"4px"}>{e.event?.name?.slice(0, 2)}</Text>
                              <Text fontSize={"13px"}>{removedEmojiEventName(e.event?.name)}</Text>
                            </WrapItem>
                          </Wrap>
                          <SimpleGrid columns={4} gap={"8px"}>
                            {
                              sortBy(photoCards, (p) => { return -p.collectedCount }).map((photoCard) => {
                                return (
                                  <AddTradingPhotoCard
                                    key={photoCard.photoCardId}
                                    photoCard={photoCard}
                                    onClickPhotoCard={(p, e) => selectPhotoCard(p, e)}
                                  />
                                )
                              })
                            }
                          </SimpleGrid>
                        </Stack>
                      )
                    }
                  }
                />
              )
            )
          }
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onClick={() => setIsCheckPhotoCardBottomSheetOpen(false)} />
    </Sheet>
  )
}
