import React, { useRef, useState } from "react";

import { Box, HStack, Image, Input, Spacer, Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import { chain, filter, isEmpty } from "lodash";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import Page from "../components/Page";
import CommonDialog from "../components/alerts/CommonDialog";
import CommonVerticalButtonDialog from "../components/alerts/CommonVerticalButtonDialog";
import { useArtistQueryAll } from "../components/api/queries/useArtistQuery";
import { ReactComponent as PlusIcon } from "../icons/plus.svg";
import baseUrl from "../utils/baseUrl";
import { getArtistId } from "../utils/etcUtils";
import getToken from "../utils/getToken";
import saveData from "../utils/saveData";
import LightColors from "../constants/LightColors";

const ArtistFrame = ({ artist, onBoxClick, onUnlikeClick }) => {
  return (
    <Box
      backgroundColor={"white"}
      borderRadius="18px"
      px="20px" py="16px"
      width= "100%"
      maxWidth= "calc(var(--chakra-sizes-xl))"
      style={{
        boxShadow: "0px 3px 20px 0px #0000000A, 0px 0px 8px 0px #00000005"
      }}
    >
      <HStack>
        <Box
          style={{
            position: 'relative'
          }}
          onClick={() => onBoxClick(artist)}
        >
          <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}>
            <svg width="52px" height="52px" opacity={0.03}>
              <circle cx="26" cy="26" r="24" fill="none" stroke="#000000" strokeWidth="1" />
            </svg>
          </div>

          <Image
            style={{
              paddingLeft: "2px",
              paddingTop: "2px",
              width: "52px",
              height: "52px",
              borderRadius: "50%",
              objectFit: "cover"
            }}
          src={artist.thumbnailUrl || artist.pictureUrl}
          //thumbnailUrl
          //pictureUrl
          />
        </Box>

        <VStack spacing="5px" alignItems="left" pl={"8px"} onClick={() => onBoxClick(artist)}>
          <Text fontWeight={600} fontSize="14px" lineHeight="17px" textColor="#444444">
            {t("manageArtistPage.likedDays", { fandom: artist.fandomName, likedDays: artist.likedDays })}
          </Text>
          <Text fontWeight={500} fontSize="12px" lineHeight="14px" textColor="#666666" decoration={"underline"}>{artist.likedDate?.replaceAll('-', '. ')}</Text>
        </VStack>

        <Spacer/>
        <Box
          borderRadius="32px"
          backgroundColor="#444444"
          minWidth={"48px"}
          minHeight={"28px"}
          px="12px"
          py="6px"
          onClick={() => onUnlikeClick?.(artist)}
        >
          <Text
            textColor="white"
            fontWeight={500}
            fontSize="13px"
            lineHeight="15.6px">
            {t("manageArtistPage.dislikeArtist")}
          </Text>
        </Box>
      </HStack>
    </Box>
  );
};

const AddFrame = () => {
  const navigate = useNavigate();
  return (
    <Box
      backgroundColor={"white"}
      borderRadius="18px"
      px="20px" py="16px"
      width= "100%"
      maxWidth= "calc(var(--chakra-sizes-xl))"
      style={{
        boxShadow: "0px 0px 24px 0px #FFFFFF4D inset, 0px 4px 10px 0px #0000000A"
      }}
      onClick={() => navigate(`/artists?artistId=register_artist`)}
    >
      <HStack>
        <Box
          as="button"
          style={{
            position: 'relative',
            width: '52px',
            height: '52px'
          }}
        >
          <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}>
            <svg width="52px" height="52px">
              <circle cx="26" cy="26" r="24" fill="none" stroke="#B2A3FF" strokeWidth="2" strokeDasharray="5,5" />
            </svg>
          </div>

          <div style={{ position: 'absolute', top: '14px', left: '14px', zIndex: 1 }}>
            <PlusIcon fill={"#B2A3FF"}/>
          </div>
        </Box>
        <Box/>

        <Text fontWeight={600} fontSize="14px" lineHeight="16.8px" textColor="#444444">
          {t("manageArtistPage.likeArtist")}
        </Text>

        <Spacer/>
      </HStack>
    </Box>
  );
}

async function unlikeArtist(artistId) {
  return fetch(baseUrl() + `/api/v1/artists/${artistId}/unlike`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }
  })
}

async function updateArtistLikedDate(artistId, likedDate) {
  return fetch(baseUrl() + `/api/v1/artists/${artistId}/liked_date`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      liked_date: likedDate,
    }),
  });
}

export default function ManageArtistPage() {
  const dateInputRef = useRef(null);
  const artists = useArtistQueryAll();
  const queryClient = useQueryClient();
  const [unlikeRequestArtist, setUnlikeRequestArtist] = useState(null);
  const [selectedArtist, setSelectedArtist] = useState(null);

  const onUnlike = (artist) => {
    if (!artists) return;
    if (filter(artists, a => !!a.likedDate).length <= 1) {
      alert(t('manageArtistPage.atLeastOneMoreArtistAlert'));
      return;
    }
    setUnlikeRequestArtist(artist);
  }

  const onUnlikeArtist = async (artist) => {
    const response = await unlikeArtist(artist.artistId);
    if(response && response.ok) {
      if (artist.artistId === getArtistId()) {
        const firstLikeArtist = chain(artists)
          .filter(a => !!a.likedDate && a.artistId !== artist.artistId)
          .first()
          .value();
        saveData(firstLikeArtist.artistId);
      }
      queryClient.clear();
    } else {
      return alert(t("requestFailed"));
    }
  }

  const handleDialogButtonClick = async () => {
    const date = dateInputRef.current.value;
    if (selectedArtist && !isEmpty(date) && isValidDate(date)) {
      const response = await updateArtistLikedDate(selectedArtist.artistId, date);
      if (response.ok) {
        setSelectedArtist(null);
        queryClient.invalidateQueries(["likedAllArtist"]);
      }
    } else {
      alert(t("manageArtistPage.pleaseCheckInvalidDateAlert"))
    }
  };

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    return !isNaN(date) && date <= now; // 유효한 날짜이고 현재 날짜보다 이전이어야 함
  };


  return (
    <Page
      title={t("manageArtistPage.pageName")}
      showBackButton={true}
      showTitle={true}
      noGutter={true}
      pb={"24px!important"}
      pt={"19px!important"}
      >
      <>
      <VStack paddingBottom={"48px"}>
        {artists
          ?.filter((a) => !!a.likedDate)
          .map((artist) => (
          <ArtistFrame
            key={artist.artistId}
            artist={artist}
            onBoxClick={(artist) => setSelectedArtist(artist)}
            onUnlikeClick={onUnlike}
          />
        ))}
        {
          artists
            && filter(artists, (a) => !!a.likedDate).length < artists.length
            && <AddFrame />
        }
      </VStack>

      <CommonVerticalButtonDialog
        isOpen={!!unlikeRequestArtist}
        onClose={() => setUnlikeRequestArtist(null)}
        title={t("manageArtistPage.disLikeArtistDialogTitle", {artist: unlikeRequestArtist?.name})}
        description={t("manageArtistPage.disLikeArtistDialogDescription")}
        upButtonText={t("manageArtistPage.disLikeArtistDialogConfirm")}
        downButtonText={t("cancel")}
        onClickDialogUpButton={async () => {
          let artist = unlikeRequestArtist;
          await onUnlikeArtist(artist);
          setUnlikeRequestArtist(null);
        }}
        onClickDialogDownButton={() => setUnlikeRequestArtist(null)}
      />

      <CommonDialog
        isOpen={!!selectedArtist}
        onClose={() => setSelectedArtist(null)}
        title={t("manageArtistPage.changeStartLikedDateDialogTitle", {fandom: selectedArtist?.fandomName})}
        children={
          <Input
            defaultValue={selectedArtist?.likedDate}
            marginY={"12px"}
            borderRadius={"12px"}
            bgColor={LightColors.secondary}
            focusBorderColor={LightColors.secondary}
            _hover={{bgColor: LightColors.purewhite}}
            size='md'
            type="date"
            ref={dateInputRef}
          />
        }
        yesButtonText={t("manageArtistPage.changeStartLikedDateDialogConfirm")}
        noButtonText={t("cancel")}
        onClickDialogButton={() => handleDialogButtonClick()}
      />
      </>
    </Page>
  )
}
