import styled from "styled-components";
import LightColors from "../../constants/LightColors";

const StyledButton = styled.button`
  min-width: 81px;
  min-height: 39px;
  padding: 11px 14px;
  border-radius: 43px;
  border: 1px solid ${LightColors.primary};
  color: ${LightColors.primary};
  gap: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
`;

const NumberBox = styled.div`
  min-width: 13px;
  min-height: 13px;
  padding: 0px 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${LightColors.primary};
  color: white;
  font-size: 9px;
  font-weight: 600;
  line-height: 11px;
  text-align: center;
  letter-spacing: 0em;
  font-size: 9px;
  font-weight: 600;
  line-height: 11px;
  text-align: center;
`;

const SecondaryLineButton = ({ text, number, onClickButton, ...props }) => {
  return (
    <StyledButton onClick={() => onClickButton()} {...props}>
      {text}
      {number && <NumberBox>{number}</NumberBox>}
    </StyledButton>
  );
};

export default SecondaryLineButton;
