import React from "react";

import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import { Badge, Box, Image, Text, VStack } from '@chakra-ui/react';
import FilledButton from "../FilledButton";
import removedEmojiEventName from "../../utils/TextUtil";
import { Virtuoso } from "react-virtuoso";
import { t } from "i18next";
export default function TradingPhotoCardWindowList({
  tradingPhotoCards,
  addTradingPhotoCard,
  removeTradingPhotoCard,
  windowHeight="63vh",
  editable=false
}) {
  return (
    <div style={{marginTop: "16px"}}>
      <Virtuoso
        data={tradingPhotoCards}
        style={{ height: "63vh" }}
        overscan={100}
        itemContent={(index, p) => (
          <div>
          <Box key={p.photoCardId} borderWidth={1} padding={"10px"} width={"100%"} borderRadius={"12px"}>
            <div className="flex">
              <div className="flex-none" style={{paddingLeft: "6px", marginTop: "6px", marginBottom: "6px", marginRight: "14px"}}>
                <Image
                  src={p.pictureUrl}
                  width={"48px"}
                  height={"75px"}
                  margin={"0px!important"}
                  borderRadius={"8px"}
                />
              </div>
              <div className="flex-grow">
                <VStack alignItems={"start"} spacing={0} paddingTop={"6px"}>
                  <Text noOfLines={1} fontSize={"15px"} lineHeight={"18px"} fontWeight={600} color={"#222"}>{removedEmojiEventName(p.event?.name)}</Text>
                  <Text noOfLines={1} paddingTop={"4px"} fontSize={"14px"} lineHeight={"17px"} fontWeight={500} color={"#666"} className="overflow-hidden">{p.event?.description}</Text>
                  <Badge marginTop={"12px!important"} paddingX={"8px"} paddingY={"4px"} borderRadius={"4px"} fontSize={"13px"} fontWeight={600} lineHeight={"16px"}>{p.member.name}</Badge>
                </VStack>
              </div>
              <div className="flex-none">
              {
                !!editable ?
                <>
                  <div className="flex justify-end" onClick={()=>removeTradingPhotoCard(p, index)}>
                    <CloseIcon width={"28px"} height={"28px"} fill="#5C3DFA" style={{ backgroundColor: "rgba(92, 61, 250, 0.06)", borderRadius: "32px", padding: "6px"}}/>
                  </div>
                </>
                :
                <FilledButton
                  paddingX={"12px"}
                  paddingY={"6px"}
                  fontSize={"13px"}
                  lineHeight={"16px"}
                  borderRadius={"32px"}
                  fontWeight={500}
                  text={t("addTradingPhotoCard.addButton")}
                  onClicked={()=>addTradingPhotoCard(p, p.event)}
                />
              }
              </div>
            </div>
          </Box>
          </div>
        )}
      />
    </div>
  )
}
