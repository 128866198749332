import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const CircleMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageWrapper = styled.div`
  border-radius: 50%;
  box-sizing: border-box;
  background: linear-gradient(91.59deg, rgba(255, 255, 255, 0.5) 31.85%, rgba(255, 255, 255, 0.1) 102.92%);
  border-image-source: linear-gradient(134.41deg, rgba(255, 255, 255, 0.6) 5.69%, rgba(255, 255, 255, 0) 95.22%);
  box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 0.3) inset;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
  padding: 17px;
`;

const Title = styled.div`
  padding-top: 7px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
`

export default function CircleMenu({title, imageSvgBase64, linkPath, deeplinkPath}) {
  const navigate = useNavigate();
  return(
    <CircleMenuWrapper onClick={() => navigate(linkPath)}>
      <ImageWrapper>
        {
          imageSvgBase64 && <div dangerouslySetInnerHTML={{__html: atob(imageSvgBase64)}}/>
        }
      </ImageWrapper>
      <Title>{title}</Title>
    </CircleMenuWrapper>
  )
}
