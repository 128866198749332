import { isEmpty } from "lodash";

export default function removedEmojiEventName(name) {
  return name?.slice(2)
}

export function isEnglishOrNumber(text) {
  return /^[a-zA-Z0-9]*$/.test(text);
}

export function ellipsis(text, maxLength=10) {
  if(isEmpty(text)) return "";

  if(text.length <= maxLength){
    return text
  }
  return text.slice(0, maxLength) + ".."
}

export function boldWordsInText(text, wordsToBold) {
  const regex = new RegExp(`(${wordsToBold.join('|')})`, 'g');
  return text.split(regex).map((word, index) => {
    return wordsToBold.includes(word) ? <strong key={index}>{word}</strong> : word;
  });
}

export function hasBatchim(text) {
  const finalChrCode = text.charCodeAt(text.length - 1)
  const finalConsonantCode = (finalChrCode - 44032) % 28
  return finalConsonantCode !== 0
};

export function extractPrice(priceStr) {
  if(isEmpty(priceStr)) return 0;
  // 정규 표현식을 사용하여 숫자와 소수점만 추출
  const priceMatch = priceStr.match(/\d+(\.\d+)?/g);
  // 배열로 반환된 값을 하나의 문자열로 결합 후, 숫자로 변환
  return parseFloat(priceMatch.join(''));
}

export const capitalizeFirstLetter = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

