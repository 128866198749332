export default class PhotoCardResponse {
  constructor({
    photoCardId,
    memberId,
    producibleType,
    producibleId,
    filename,
    name,
    visibility,
    artistId,
    memberName,
    eventId,
    eventName,
    eventSlug,
    pocaname,
    pictureUrl,
    collectedCount,
    isWished,
  }) {
    this.photoCardId = photoCardId;
    this.memberId = memberId;
    this.producibleType = producibleType;
    this.producibleId = producibleId;
    this.filename = filename;
    this.name = name;
    this.visibility = visibility;
    this.artistId = artistId;
    this.memberName = memberName;
    this.eventId = eventId;
    this.eventName = eventName;
    this.eventSlug = eventSlug;
    this.pocaname = pocaname;
    this.pictureUrl = pictureUrl;
    this.pictureUrl = pictureUrl;
    this.collectedCount = collectedCount;
    this.isWished = isWished;
  }
}
