import React, { useEffect, useState } from "react"

import { Box, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Text, useToast } from "@chakra-ui/react"
import { t } from "i18next"
import { isEmpty } from "lodash"
import { useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"

import { ReactComponent as LikeLineIcon } from "../../../icons/like.line.svg"
import { ReactComponent as LikeShapeIcon } from "../../../icons/like.shape.svg"
import baseUrl from "../../../utils/baseUrl"
import getToken from "../../../utils/getToken"
import ToastMessageBox from "../../alerts/ToastMessageBox"
import PrimaryButton from "../../buttons/PrimaryButton"
import TradingPhotoCardInfo from "../../tradings/TradingPhotoCardInfo"
import createChatRoom from "../mutations/legacy/createChatRoom"
import useUserProfilesListQuery from "../queries/useUserProfilesListQuery"



const headerStyle = {
  paddingTop: "24px",
  paddingBottom: "0px",
}

const titleStyle = {
  fontSize: "17px",
  fontWeight: 700,
}

const buttonStyle={
  padding: "16px 24px"
}

const likeBoxStyle={
  width: "52px",
  height: "52px",
  padding: "16px 14px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "52px",
  backgroundColor: "rgba(92, 61, 250, 0.06)"
}

const wishedIconStyle = {
  width: "24px",
  height: "24px"
}

async function toggleWished(tradingPhotoCardId, wished) {
  const url = `/trading_photo_cards/${tradingPhotoCardId}/${wished ? "unwished" : "wished"}`;
  return fetch(baseUrl() + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }
  }).then((data) => data.json())
}

async function addBlacklistCard(tradingPhotoCardId) {
  return fetch(baseUrl() + `/trading_photo_cards/${tradingPhotoCardId}/blacklisted`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
  }).then((data) => data.json())
}

export default function ExploreTradingPhotoCardBottomDrawer({isOpen, onClose, focusRef, artistId, tradingPhotoCard}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const currentPath = window.location.pathname;
  const toast = useToast();

  const [wished, setWished] = useState(tradingPhotoCard.isWished)
  const [enableBackdrop, setEnableBackdrop] = useState(false);
  const [isShowProfileBottomSheet, setIsShowProfileBottomSheet] = useState(false);

  // Defence code to close immediately Bottomsheet
  useEffect(() => {
    if(isOpen) {
      setTimeout(() => { setEnableBackdrop(true) }, 300);
    } else {
      setEnableBackdrop(false);
    }
  }, [isOpen])

  const tradingUserProfiles = useUserProfilesListQuery("trading");

  const navigateChatRoom = async () => {
    // Check trading user profile Empty or Multiple
    if (isEmpty(tradingUserProfiles)) {
      onClose();
      return setIsShowProfileBottomSheet(true);
    } else if (tradingUserProfiles.length > 1) {
      if (currentPath.startsWith("/photo_cards/")) {
        return navigate(`/users/merge_profiles?backUrl=${currentPath}`, { replace: true });
      } else {
        return navigate(`/users/merge_profiles`, { replace: true });
      }
    }

    const response = await createChatRoom(artistId, tradingPhotoCard.tradingPhotoCardId);
    if (response.success) {
      navigate(`/artists/${artistId}/chats/${response.chatRoom.chatRoomId}`)
    } else if (response.error && response.error.code === "1001" && response.error.message.startsWith("Not found record")) {
      onClose();
      return setIsShowProfileBottomSheet(true);
    } else {
      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox message={t("exploreTradingPhotoCardBottomSheet.errorNoProfileToast")}/>
        ),
      })
    }
  }

  const onClickWished = (tradingPhotoCardId) => {
    toggleWished(tradingPhotoCardId, wished)
    setWished(!wished)
  }

  const onClickBlacklisted = (tradingPhotoCardId) => {
    addBlacklistCard(tradingPhotoCardId)
    queryClient.invalidateQueries("exploreTradingPhotoCards");
    queryClient.invalidateQueries("matchedTradingPhotoCards");
    setTimeout(() => { onClose() }, 300);
  }

  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement='bottom'
        finalFocusRef={focusRef}
      >
        <DrawerOverlay/>
        <DrawerContent
          motionProps={{
            variants: {
              enter: {
                y: "0%",
                transition: { duration: 0.15 },
              },
              exit: {
                y: "100%",
                transition: { duration: 0.1 },
              },
            }
          }}
          style={{
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px"
          }}
        >
          <DrawerHeader style={headerStyle}>
            <SimpleGrid columns={2}>
              <Text style={titleStyle}>{t("exploreTradingPhotoCardBottomSheet.tradingPhotoCardInfo")}</Text>
              <div style={{justifySelf: "end"}}>
                <MeatBallMenus tradingPhotoCardId={tradingPhotoCard.tradingPhotoCardId} onClickBlacklisted={onClickBlacklisted}/>
              </div>
            </SimpleGrid>
          </DrawerHeader>
          <DrawerBody paddingX={"20px"}>
            <TradingPhotoCardInfo tradingPhotoCard={tradingPhotoCard} showProfile={true} showPrice={true}/>
            <div className="flex" style={{paddingTop: "24px"}}>
            <div className="flex-none self-center" style={{paddingRight: "8px"}}>
              <Box style={likeBoxStyle} onClick={() => onClickWished(tradingPhotoCard.tradingPhotoCardId)}>
                {
                  wished ? <LikeShapeIcon style={wishedIconStyle} fill={"#5C3DFA"}/> : <LikeLineIcon style={wishedIconStyle} fill={"#5C3DFA"}/>
                }
              </Box>
            </div>
            <div className="flex-grow" onClick={() => navigateChatRoom()}>
              <PrimaryButton text={t("exploreTradingPhotoCardBottomSheet.sendMessage")} style={buttonStyle} />
            </div>
          </div>
          </DrawerBody>
          <DrawerFooter className="safeAreaMarginBottom" width={"100%"} paddingTop={"10px"} paddingBottom={"0px"} paddingX={"20px"}>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

function MeatBallMenus({tradingPhotoCardId, onClickBlacklisted}) {
  const navigate = useNavigate();
  return(
    <Menu>
      <MenuButton>
        <Text fontSize={"18px"} fontWeight={600}>{"⋯"}</Text>
      </MenuButton>
      <MenuList>
        <MenuItem fontSize="14px" lineHeight="17px" fontWeight={500} onClick={() => onClickBlacklisted(tradingPhotoCardId)}>{t("tradingPhotoCardPage.addBlackListMenu")}</MenuItem>
        <MenuItem fontSize="14px" lineHeight="17px" fontWeight={500} onClick={() => navigate(`/trading_reports?tradingPhotoCardId=${tradingPhotoCardId}`)}>{t("tradingPhotoCardPage.reportMenu")}</MenuItem>
      </MenuList>
    </Menu>
  )
}
