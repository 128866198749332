import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function useCollectedPhotoCardIdsQuery(artistId, memberId) {
  const { data: collectedPhotoCardIds } = useQuery(
    ["collectedPhotoCardIds", artistId, memberId],
    () => queryGet(`/api/v1/artists/${artistId}/photo_cards/collected_ids?member_id=${memberId}`),{
      select: (data) => data.collectedPhotoCardIds,
      enabled: !!artistId && !!memberId,
    }
  );
  return collectedPhotoCardIds;
}
