import React, { useEffect, useMemo, useState } from "react";

import { Center, ScaleFade, Spinner, Text, useToast } from '@chakra-ui/react';
import { t } from "i18next";
import { first, isEmpty, last, shuffle, throttle } from "lodash";
import Marquee from "react-fast-marquee";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from 'react-spring';
import styled from "styled-components";

import Page from "../../components/Page";
import updateUserLocaleOrCountry from "../../components/api/mutations/updateUserLocaleOrCountry";
import useArtistQuery from "../../components/api/queries/useArtistQuery";
import usePostsQuery from "../../components/api/queries/usePostsQuery";
import useRefreshJwtQuery from "../../components/api/queries/useRefreshJwtQuery";
import useUserQuery from "../../components/api/queries/useUserQuery";
import FabButton from "../../components/buttons/FabButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import MyCollectedPhotoCardsStat from "../../components/community/MyCollectedPhotoCardsStat";
import PostsList from "../../components/community/PostsList";
import HomeHeader from "../../components/home/HomeHeader";
import HorizontalScollMenus from "../../components/home/HorizontalScollMenus";
import CheckIcon from "../../components/icons/CheckIcon";
import WhiteToastMessageBox from "../../components/toast/WhiteToastMessageBox";
import SwitchToggleBar from "../../components/tradings/SwitchToggleBar";
import LightColors from "../../constants/LightColors";
import HomeBottomNav from "../../layout/HomeBottomNav";
import { openNewTab } from "../../utils/UIUtils";
import {
  getArtistId,
  getArtistIdAnyway,
  getLanguage,
} from "../../utils/etcUtils";
import { queryGet } from "../../utils/queryClient";
import saveData from "../../utils/saveData";
import SelectFeedCountryBottomDrawer from "../../components/api/drawer/SelectFeedCountryBottomDrawer";
import i18n from "../../i18n/i18n";

const HeaderWrapper = styled.div`
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 24px;
  padding-right: 16px;
`

const HorizontalWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 23px;
  border-bottom: 1px solid ${LightColors.black050};
`

const HomeArtistTabSelectorWrapper = styled.div`
  padding-left: 24px;
  margin-top: 4px;
`

const NewPostsNotification = styled.div`
  z-index: 12345;
  position: fixed;
  top: 60px;
  width: 100%;
`

const GradientButton = styled.div`
  padding: 11px 18px;
  border-radius: 39px;
  background: linear-gradient(262.08deg, #7D4CF1 -5.44%, #00B3FF 101.76%);
  box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.25);
  color: ${LightColors.purewhite}
`

const SwitchToggleBarWrapper = styled.div`
  margin-top: 18px;
  padding: 6px 18px;
`

const PAGING_SIZE = 3

export default function FeedHomePage() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();

  const [selectedArtist, setSelectedArtist] = useState();
  const [paginatedPosts, setPaginatedPosts] = useState();
  const [currentPagination, setCurrentPagination] = useState();
  const [isShowNewPostsNotification, setIsShowNewPostsNotification] = useState(false);
  const [onlyArtist, setOnlyArtist] = useState(false);
  const [hiddenCollectedPhotoCardStat, setHiddenCollectedPhotoCardStat] = useState(localStorage.getItem("hiddenCollectedPhotoCardStat") === "true");
  const [isShowCountryDrawer, setIsShowCountryDrawer] = useState(false);
  const [countryCode, setCountryCode] = useState(localStorage.getItem("feedCountryCode"));

  useEffect(() => {
    const showFeedIntroPage = localStorage.getItem("showFeedIntroPage");
    if (showFeedIntroPage === null || showFeedIntroPage === true) {
      navigate("/feed/intro");
    };

    // const appVersion = localStorage.getItem('appVersion')
    // if(isIOS && (isEmpty(appVersion) || !appVersion.includes("1.1.0"))) {
    //   navigate('/ask_update')
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authToken = useRefreshJwtQuery();
  if (authToken?.jwt) {
    saveData("token", JSON.stringify(authToken?.jwt));
  }

  const user = useUserQuery();
  useEffect(() => {
    if (isEmpty(user) || isEmpty(user.locale) || getLanguage() === user.locale) return;
    saveData("i18nextLng", user.locale);
    i18n.changeLanguage(user.locale);
  }, [user]);

  if(user && isEmpty(user?.country) && !isEmpty(countryCode)) {
    updateUserLocaleOrCountry({userId: user?.userId, country: countryCode})
    queryClient.invalidateQueries("users");
  }

  const shuffledFirstArtist = useMemo(() => { return first(shuffle(user?.likedArtists)) }, [user])

  const { data, isFetching, fetchNextPage, hasNextPage, refetch } = usePostsQuery({
    country: countryCode || user?.country,
    artistId: selectedArtist?.artistId,
  });

  const artistId = getArtistIdAnyway(selectedArtist) || getArtistId();
  const artist = useArtistQuery(artistId);

  useEffect(() => {
    if(!isEmpty(selectedArtist)) {
      setPaginatedPosts(undefined)
      setCurrentPagination(undefined);
      queryClient.resetQueries({ queryKey: ["Posts", selectedArtist.artistId], exact: true })
      // Refetch only first pagination
      refetch({
        refetchPage: (page, index) => index === 0,
        resetPageOnRefetch: true,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArtist])


  const onArtistSelected = (artist) => {
    setSelectedArtist(artist);
  }

  const onToggledSwitch = (checked) => {
    if(!checked) {
      setSelectedArtist(null);
    }
    setOnlyArtist(checked);
  }

  const handlePaginatedPosts = (paginatedData) => {
    const posts = paginatedData.pages?.flatMap((page) =>
      page.posts.map((tradingPhotoCard) => tradingPhotoCard)
    )
    setPaginatedPosts(posts);
  }

  const loadMore = () => {
    if(!isFetching && hasNextPage) {
      fetchNextPage()
    }
  }
  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedPosts(data);
      setCurrentPagination(last(data.pages)?.meta?.pagination);
    }
  }, [data]);

  useEffect(() => {
    if (!isShowNewPostsNotification && currentPagination && currentPagination.currentPage % PAGING_SIZE === 0) {
      const lastestPost = first(paginatedPosts)
      const fetchLatestPostsCount = async () => {
        const response = await queryGet(`/api/v1/posts/${lastestPost.postId}/latest_posts_count`);
        if (response.latestPostsCount >= 10) {
          setIsShowNewPostsNotification(true);
        }
      };
      if(lastestPost?.postId) {
        fetchLatestPostsCount();
      }
    }
  }, [isShowNewPostsNotification, currentPagination, paginatedPosts]);

  const refetchNewPosts = () => {
    window.scrollTo({top: 0, behavior: "smooth"});
    setIsShowNewPostsNotification(false);
    // Reset Pagination
    setCurrentPagination(null);
    // Refetch only first pagination
    refetch({
      refetchPage: (page, index) => index === 0,
      resetPageOnRefetch: true,
    });
  }

  const navigateAddUserProfile = () => {
    navigate("/feed/create_feed_user_profile");
  }

  const navigateAddPost = () => {
    if (isEmpty(user?.baseUserProfile)) {
      navigateAddUserProfile();
    } else {
      let postAddUrl = "/posts/add"

      if (!isEmpty(selectedArtist)) {
        const queryParams = new URLSearchParams({
          artistId: selectedArtist.artistId,
          fandomName: selectedArtist.fandomName,
        });
        postAddUrl = `${postAddUrl}/?${queryParams.toString()}`
      }
      openNewTab(postAddUrl);
    }
  }

  const showToastMessage = (postId) => {
    toast({
      position: 'top',
      duration: 2000,
      render: () => (
        <WhiteToastMessageBox
          startElement={<CheckIcon fill={LightColors.verfied}/>}
          message={t("feedPage.uploadToastMessage")}
          endElement={<SecondaryButton
            lineHeight={"16px"}
            paddingX={"12px"}
            paddingY={"6px"}
            fontSize={"13px"}
            backgroundColor={LightColors.textmainlv2}
            color={LightColors.purewhite}
            text={t("feedPage.uploadToastShowPost")}
            onClick={() => openNewTab(`/posts/${postId}`)}/>
          }
        />
      ),
    })
  }

  useEffect(() => {
    const receiveMessage = throttle((event) => {
      if(event.origin !== window.location.origin) return;
      if(isEmpty(event.data?.type)) return;

      if (event.data?.type === "refresh-post-feeds-by-add-post-page") {
        queryClient.invalidateQueries("Posts");
      } else if (event.data?.type === "show-toast-uploaded-post-on-feed") {
        const post = event.data?.params?.post
        if(!isEmpty(post)) {
          showToastMessage(post.postId);
        }
      }
    }, 1000);

    window.addEventListener("message", receiveMessage, false);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient]);

  // #region pull to refresh
  const [refreshing, setRefreshing] = useState(false);
  const [lastY, setLastY] = useState(null);
  const props1 = useSpring({
    to: {
      transform: refreshing ? 'translateY(88px)' : 'translateY(0px)',
      opacity: refreshing ? 1 : 0
    },
    from: {
      transform: 'translateY(0px)',
      opacity: 0
    }
  });

  const props2 = useSpring({
    to: {
      transform: refreshing ? 'translateY(88px)' : 'translateY(0px)',
    },
    from: {
      transform: 'translateY(0px)'
    }
  });

  const handleTouchStart = (e) => {
    setLastY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    const newY = e.touches[0].clientY;
    const deltaY = newY - lastY;
    if (refreshing) {
      return;
    }
    // console.log(deltaY);
    // console.log(`y : ${newY}, ${refreshing}, ${window.scrollY}`);
    if (deltaY > 150 && window.scrollY <= 0) {
      // alert('good');
      setRefreshing(true);
      const startTime = Date.now(); // 현재 시간 기록
      refetch({
        refetchPage: (page, index) => index === 0,
        resetPageOnRefetch: true,
      }).then(() => {
        const elapsedTime = Date.now() - startTime; // 경과 시간 계산
        const remainingTime = Math.max(1500 - elapsedTime, 0); // 남은 시간 계산
        setTimeout(() => {
          setRefreshing(false);
        }, remainingTime);
      });
    }
  };
  // const handleTouchEnd = () => {
  //   if (refreshing) {
  //     // 새로고침 작업 수행
  //     setRefreshing(false);
  //   }
  // };
  // #endregion

  const onHiddenCollectedPhotoCardStat = () => {
    saveData("hiddenCollectedPhotoCardStat", true);
    setHiddenCollectedPhotoCardStat(true);
  }

  const onConfirmedCountryCode = (countryCode) => {
    saveData("feedCountryCode", countryCode);
    queryClient.invalidateQueries("Posts");
    setCountryCode(countryCode);
    setIsShowCountryDrawer(false);
  }

  return (
    <div className="homeBackground safeAreaPaddingExceptBottomBackground">
    {
      <Page
        title={t("feedPage.title")}
        showBackButton={false}
        themeColor={"#C6DBF8"}
        userProfile={user}
        noPadding={true}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        // onTouchEnd={handleTouchEnd}
      >
        {
          <NewPostsNotification>
            <Center>
              <ScaleFade initialScale={0.5} in={isShowNewPostsNotification}>
                <GradientButton onClick={() => refetchNewPosts()}>
                  {t("feedPage.newPostNotification")}
                </GradientButton>
              </ScaleFade >
            </Center>
          </NewPostsNotification>
        }
        {
          <animated.div style={{
            ...props1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Spinner color="#C2C1C7"/>
          </animated.div>
        }
        <animated.div style={props2}>
          <HeaderWrapper>
            <HomeHeader
              title={t("feedPage.title")}
              user={user}
              onClickTitle={() => setIsShowCountryDrawer(true)}
              onClickProfileButton={() => navigateAddUserProfile()}
            />
          </HeaderWrapper>
          <HorizontalWrapper>
            <HorizontalScollMenus/>
          </HorizontalWrapper>

          {/* <HomeArtistTabSelectorWrapper>
            <HomeArtistTabSelector artists={user?.likedArtists} onSelected={onArtistSelected}/>
          </HomeArtistTabSelectorWrapper> */}
          <Marquee style={{background: "linear-gradient(90deg, #458FFF 0%, #CF6AFF 100%)",width: "100%",}}>
            <Text paddingY={"10px"} paddingX={"13px"} fontWeight={600} fontSize={"12px"} lineHeight={"14px"} color={LightColors.purewhite}>
              {t("feedPage.alertMarquee")}
            </Text>
          </Marquee>

          {
            <SwitchToggleBarWrapper>
              <SwitchToggleBar
                text={t("feedPage.onlyFandom")}
                checked={onlyArtist}
                onToggled={(checked) => onToggledSwitch(checked)}
                onSelectedArtist={onArtistSelected}
              />
            </SwitchToggleBarWrapper>
          }
          {
            (!selectedArtist && !hiddenCollectedPhotoCardStat) &&
            <div>
              {/* <MyEventPhotoCardsPost user={user} artist={artist} member={member}/> */}
              { <MyCollectedPhotoCardsStat user={user} artist={artist} onHiddenCollectedPhotoCardStat={onHiddenCollectedPhotoCardStat}/> }
            </div>
          }
          <PostsList
            user={user}
            posts={paginatedPosts}
            artistId={selectedArtist?.artistId || shuffledFirstArtist?.artistId}
            loadMore={loadMore}
            hasMore={hasNextPage}
          />
        </animated.div>
    </Page>
    }
    <HomeBottomNav tabIndex={0}/>
    <FabButton
      width={"52px"}
      p={"10px!important"}
      onClickFabButton={() => navigateAddPost()}
    />
    {
      isShowCountryDrawer &&
      <SelectFeedCountryBottomDrawer
        isOpen={isShowCountryDrawer}
        onClose={() => setIsShowCountryDrawer(false)}
        defaultCountryCode={user?.country}
        onConfirmedCountryCode={onConfirmedCountryCode}
      />
    }

    </div>
  )
}
