import React from "react";
import { Image, Text, WrapItem } from '@chakra-ui/react';
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { useState } from "react";
import { t } from "i18next";

const collectedBoxStyle = { backgroundColor: "white", padding: "3px 6px", borderRadius: "34px", color: "white", marginLeft: "3px", marginBottom: "3px" }
const addButtonStyle = { backgroundColor: "#5C3DFA", borderRadius: "8px 0px", color: "white", padding: "3px" }

export default function AddTradingPhotoCard({photoCard, onClickPhotoCard, ...props}) {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div key={photoCard.photoCardId} onClick={() => onClickPhotoCard(photoCard, photoCard.event)} {...props}>
    {
      (
        <WrapItem className="relative">
          <Image
            src={photoCard.pictureUrl}
            width={"100%"}
            minHeight={"120px"}
            borderRadius="8px"
            loading={"lazy"}
            objectFit={"cover"}
            onLoad={() => setIsLoaded(true)}
          />
          {
            (isLoaded && photoCard.collectedCount > 0) && <div className="absolute bottom-0 left-0" style={collectedBoxStyle}>
              <Text color={"#5C3DFA"} fontSize={"10px"} fontWeight={600}>{t("addTradingPhotoCard.collectedBadge")}</Text>
            </div>
          }
          {
            isLoaded && <div className="absolute bottom-0 right-0" style={addButtonStyle}>
              <PlusIcon width={"20px"} height={"20px"} fill={"white"}/>
            </div>
          }
        </WrapItem>
      )
    }
    </div>
  )
}
