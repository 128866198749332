import React, { useEffect, useState } from "react";

import { t } from "i18next";
import { isEmpty } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Page from "../../components/Page";
import usePostsQuery from "../../components/api/queries/usePostsQuery";
import useUserProfileQuery from "../../components/api/queries/useUserProfileQuery";
import useUserQuery from "../../components/api/queries/useUserQuery";
import AddUserProfileV1BottomSheet from "../../components/bottomSheet/AddUserProfileV1BottomSheet";
import PostsList from "../../components/community/PostsList";
import TabsListSelector from "../../components/community/TabsListSelector";
import GridSkeletons from "../../components/skeletons/GridSkeletons";
import ProfileAvatar from "../../components/thumbnails/ProfileAvatar";
import LightColors from "../../constants/LightColors";
import { ReactComponent as SettingIcon } from "../../icons/setting.svg";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
`

const TextWrapper = styled.div`
  flex-grow: 1;
`

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: #222222;
  padding-right: 12px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`

const Description = styled.div`
  padding-top: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #B2A3FF;
`

const AvatarWrapper = styled.div`
  flex-shrink: 1;
`

const TabsListSelectorWrapper = styled.div`
  padding: 14px 24px;
`

export default function UserFeedProfilePage() {
  const params = useParams();
  const navigate = useNavigate();
  const [paginatedPosts, setPaginatedPosts] = useState();
  const [isShowBaseProfileBottomSheet, setIsShowBaseProfileBottomSheet] = useState(false);

  const [selectedTab, setSelectedTab] = useState();
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);

  const user = useUserQuery();
  const userProfile = useUserProfileQuery(params["user_profile_id"]);
  const { data, isFetching, fetchNextPage, hasNextPage } = usePostsQuery({
    userProfileId: userProfile?.userProfileId,
    anonymous: selectedTab?.id === "anonymous",
  });

  const handlePaginatedPosts = (paginatedData) => {
    const posts = paginatedData.pages?.flatMap((page) =>
      page.posts.map((tradingPhotoCard) => tradingPhotoCard)
    )
    setPaginatedPosts(posts);
  }
  const loadMore = () => {
    if(!isFetching && hasNextPage) {
      fetchNextPage()
    }
  }

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedPosts(data);
      // setMeta(first(data.pages)?.meta);
    }
  }, [data]);

  const onSelectedTab = (tab) => {
    setSelectedTab(tab);
  }
  const back = () => {
    const isNewTab = currentQueryParams.get("newTabOpen");
    if (isNewTab) {
      window.close();
    } else {
      navigate(-1);
    }
  }

  return (
    <Page
      showBackButton={true}
      noPadding={true}
      title={t("userFeedProfilePage.pageTitle")}
      onHistoryBackClick={() => back()}
      navigationElement={
        userProfile?.isMine ?
        <SettingIcon
          fill={LightColors.textmainlv1}
          width={"24px"}
          height={"24px"}
          onClick={() => setIsShowBaseProfileBottomSheet(true)}
        /> : <></>
      }
    >
      {
        userProfile ?
        <Header>
          <TextWrapper>
            <Title>{userProfile?.nickname}</Title>
          </TextWrapper>
          <AvatarWrapper>
            <ProfileAvatar
              profileImageUrl={userProfile?.thumbnailUrl}
              widthAndHeight={"76px"}
              svgWidthAndHeight={"48px"}
              padding={"14px"}
            />
          </AvatarWrapper>
        </Header> :
        <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={'76px'}/>
      }
      {
        <TabsListSelectorWrapper>
          <TabsListSelector onSelectedTab={onSelectedTab} hiddenTabId={userProfile?.isMine ? "" : "anonymous"}/>
        </TabsListSelectorWrapper>
      }

      {
        userProfile && <PostsList user={user} posts={paginatedPosts} loadMore={loadMore} hasMore={hasNextPage}/>
      }

    {
      (user?.baseUserProfile && setIsShowBaseProfileBottomSheet) &&
      <AddUserProfileV1BottomSheet
        title={t("myUserProfileList.changeProfile")}
        userProfile={user?.baseUserProfile}
        isShowProfileBottomSheet={isShowBaseProfileBottomSheet}
        setIsShowProfileBottomSheet={setIsShowBaseProfileBottomSheet}
        category={"base"}
        isMergeProfile={false}
      />
    }
    </Page>
  )
}
