import { isEmpty } from "lodash";

export default function filterPhotoCards(photoCards, searchText) {
  if(isEmpty(photoCards)) return;

  const upperSearchText = searchText.toUpperCase();
  const splitedText = upperSearchText.split(" ");

  // Function to calculate a basic score for a photoCard based on keyword matches
  function calculateScore(photoCard) {
    const eventText = photoCard.event?.name?.toUpperCase();
    const memberText = photoCard.member?.name?.toUpperCase();

    // Calculate score based on keyword matches in splitedText
    const score = splitedText.reduce((totalScore, keyword) => {
      return (
        totalScore +
        (eventText.includes(keyword) ? 1 : 0) +
        (memberText.includes(keyword) ? 1 : 0)
      );
    }, 0);

    return score;
  }

  // Filter and rank photoCards based on keyword matches
  const filteredAndRanked = photoCards
    .map(photoCard => ({
      ...photoCard,
      score: calculateScore(photoCard),
    }))
    .filter(photoCard => photoCard.score > 0) // Remove zero-score entries
    .sort((a, b) => b.score - a.score); // Sort by score in descending order

  return filteredAndRanked;
}
