import { Skeleton, Text, Wrap } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as LikeShapeIcon } from "../../icons/like.shape.svg";
import { getPhotoCardHeight } from "../../utils/photoCardSize";

const pocaNameTextStyle = {
  fontWeight: 600,
  fontSize: "11px",
  lineHeight: "13px",
  color: "white"
}

const likeShapeStyle = {
  width: "24px",
  height: "24px"
}

export default function PhotoCard({photoCard, isCollected, ...props}) {
  const targetRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
      });
    });

    if(targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if(targetRef.current){
        observer.unobserve(targetRef.current);
      }
    };

  }, []);

  const backgroundImageStyle = (photoCard) => {
    const backgroundColorStyle = isCollected || photoCard.collectedCount > 0  ?
      "linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.0793348) 50.31%, rgba(0, 0, 0, 0.05) 100%)" :
      "linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 52.5%)"

    return {
      width: "100%",
      height: getPhotoCardHeight("lg"),
      boxSizing: "border-box",
      borderRadius: "8px",
      backgroundColor: backgroundColorStyle,
      backgroundSize: "cover",
      backgroundImage: `url(${photoCard.pictureUrl})`
    }
  }


  return (
    <div ref={targetRef}>
    {
      isVisible ? (
        <Wrap className="relative" key={photoCard.photoCardId} {...props}>
          <div className={isCollected || photoCard.collectedCount > 0 ? "grayscale-0" : "grayscale opacity-50"} style={backgroundImageStyle(photoCard)}/>
          {
            ((isCollected && photoCard.collectedCount > 0) || (photoCard.isWished)) && (
              <div className="absolute top-0 right-0">
                <LikeShapeIcon style={likeShapeStyle} fill={"#FFF"}/>
              </div>
            )
          }
          <div className="absolute inset-x-0 bottom-0">
            <Text style={pocaNameTextStyle}>{photoCard.pocaname || photoCard.name}</Text>
          </div>
        </Wrap>
      ) : (<Skeleton width="100%" height={getPhotoCardHeight("lg")} startColor='rgba(242,242,242)' endColor='rgba(242,242,242,0.7)' borderRadius="8px" />)
    }
    </div>
  )
}
