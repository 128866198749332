import { GridItem, Image, SimpleGrid, Stack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import React from "react";
import { Virtuoso } from "react-virtuoso";
import styled from 'styled-components';
import colors from '../../../constants/colors';
import { getGridSize, getPhotoCardHeight } from '../../../utils/photoCardSize';
import CheckIcon from '../../icons/CheckIcon';

const GRID_TYPE = "lg"

const IconWrapper = styled.div`
  background-color: ${colors.primary};
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 3px;
`

export default function PhotoCardsByEventEditor({
  events,
  onClickedPhotoCard,
  endReached,
  isColorMode,
}) {

  const isEnabledCard = (photoCard) => {
    return photoCard.collectedCount > 0
  }

  // #region draw cards
  const drawCardsWithEventName = (e) => {
    if (e.photoCards <= 0) {
      return (<div key={e.eventId}></div>)
    } else {
      return(
        <Stack key={e.eventId} paddingBottom={"18px"}>
          <Wrap paddingTop={"10px"} paddingBottom={"4px"} align={"center"} marginLeft={"-1px"} spacing={0}>
            <WrapItem alignItems={"center"}>
              <Text fontSize={"20px"} marginRight={"4px"}>{e.name?.slice(0,2)}</Text>
              <Text fontSize={"13px"}>{e.name?.slice(2)}</Text>
            </WrapItem>
          </Wrap>
          <SimpleGrid columns={getGridSize(GRID_TYPE)} spacing={"4px"}>
            {(e.photoCards)?.map((photoCard) => {
              return (
                <GridItem key={photoCard.photoCardId}>
                  <WrapItem className="relative">
                    <Image
                      src={photoCard.pictureUrl}
                      className={isColorMode ? "grayscale-0" : (isEnabledCard(photoCard) ? "grayscale-0" : "grayscale")}
                      height={getPhotoCardHeight(GRID_TYPE)}
                      width={"100%"}
                      objectFit={"cover"}
                      border={isEnabledCard(photoCard) ? "2px" : ""}
                      borderColor={isEnabledCard(photoCard) ? "secondary.1000" : ""}
                      borderRadius="8px"
                      onClick={() => onClickedPhotoCard(photoCard, e.event)}
                      style={{boxSizing: "border-box"}}
                      loading="lazy"
                    />
                    {
                      isEnabledCard(photoCard) &&
                      <IconWrapper className="absolute right-0 bottom-0">
                        <CheckIcon/>
                      </IconWrapper>
                    }
                  </WrapItem>
                </GridItem>
              )
            })}
          </SimpleGrid>
        </Stack>
      )
    }
  };
  // #endregion

  return (
    <Virtuoso
      useWindowScroll
      style={{ height: '100%' }}
      endReached={endReached}
      overscan={480}
      data={events}
      itemContent={(_, e) => {return drawCardsWithEventName(e)}
    }/>
  )
}
