import { Input } from "@chakra-ui/react";
import React from "react";

export default function PrimaryInput({defaultValue, placeholder, onChangeText, ...props}) {
  return(
    <Input
      borderRadius={"12px"}
      bgColor={"rgba(92,61,250,0.06)"}
      focusBorderColor={"rgba(92,61,250,0.06)"}
      defaultValue={defaultValue}
      onChange={onChangeText}
      variant="filled"
      paddingY={6}
      _hover={{bgColor:"rgba(92,61,250,0.06)"}}
      placeholder={placeholder}
      {...props}
    />
  )
}