import React, { useState } from "react";

import { Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';

import { t } from "i18next";
import { debounce, isEmpty, isNull } from "lodash";
import colors from "../../constants/colors";
import { ReactComponent as ProfileFillIcon } from "../../icons/profile.fill.svg";
import { ellipsis } from "../../utils/TextUtil";
import { openNewTab } from "../../utils/UIUtils";
import ExploreTradingPhotoCardBottomDrawer from "../api/drawer/ExploreTradingPhotoCardBottomDrawer";
import RoundedBadge from "../badges/RoundedBadge";
import VerifiedIcon from "../icons/VerifiedIcon";

const imageBoxStyle = {
  width: "100%",
  height: "96%",
  padding: "12px 0px",
  background: "var(--gray-surface, rgba(0, 0, 0, 0.04))",
  borderRadius: "20px",
}

const imageStyle = {
  width: "48px",
  height: "75px",
  borderRadius: "8px",
  objectFit: "cover"
}

const profileStyle = {
  paddingLeft: "2px",
  fontWeight: 500,
  color: "rgba(34, 34, 34, 1)",
  lineHeight: "16px",
  fontSize: "13px",
}

const priceStyle = {
  color: "#382599",
  fontSize: "15px",
  fontWeight: 600,
  lineHeight: "18px",
  marginBottom: "6px"
}

const requestedAtStyle = {
  color: "#999",
  fontSize: "11px",
  lineHeight: "13.2px",
  fontStyle: "normal",
  fontWeight: "400",
  whiteSpace: "pre"
}

const responseRateText = {
  color: `${colors.primary}`,
  fontSize: "11px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "13.2px",
}

export default function ExploreTradingPhotoCard({tradingPhotoCard, hiddenNickname=false, hiddenChatStat=false, showEventName=false, onClickTradingPhotoCard}) {
  const [isShowExploreTradingPhotoCardBottomSheet, setIsShowExploreTradingPhotoCardBottomSheet] = useState(false)

  const showBottomSheet = () => {
    if(onClickTradingPhotoCard) {
      onClickTradingPhotoCard(tradingPhotoCard);
    } else {
      setIsShowExploreTradingPhotoCardBottomSheet(true);
    }
  };

  const debouncedShowBottomSheet = debounce(showBottomSheet, 300);

  const navigateUserTradingProfile = (userProfile) => {
    const currentPath = window.location.pathname;
    const userProfilePath = `/trading/user_profile/${userProfile?.userProfileId}`
    if (currentPath === userProfilePath) return;

    if(!isEmpty(userProfile?.userProfileId)) {
      openNewTab(userProfilePath);
    }
  }

  return (tradingPhotoCard &&
    <>
      <VStack
        spacing={0}
        align={"flex-start"}
        minHeight={"140px"}
        onClick={debouncedShowBottomSheet}
        key={tradingPhotoCard.tradingPhotoCardId}
      >
        <div className="relative w-full">
          {
            tradingPhotoCard.category === "sale" && <div className="absolute top-0 left-0" style={{paddingLeft: "7px", paddingTop: "6px"}}>
              <RoundedBadge text={t("tradingPhotoCardPage.saleBadge")} bgColor={"white"} textColor={"primary.500"}/>
            </div>
          }
          <Flex style={imageBoxStyle} justifyContent={"center"}>
            <Image style={imageStyle} src={tradingPhotoCard.photoCard.pictureUrl}/>
          </Flex>
        </div>
        {
          showEventName && <div style={{paddingTop: "6px"}}>
            <Text noOfLines={1} style={profileStyle}>{ellipsis(tradingPhotoCard.photoCard.eventName)}</Text>
          </div>
        }
        {
          hiddenNickname ? <div style={{paddingTop: "4px"}}></div> :
          <Flex
            direction={"row"}
            alignItems={"center"}
            paddingTop={"6px"}
            paddingBottom={"6px"}
            onClick={() => navigateUserTradingProfile(tradingPhotoCard?.userProfile)}
          >
            {
              tradingPhotoCard?.userProfile?.identified ?
              <VerifiedIcon width={"14px"} height={"14px"} /> :
              <ProfileFillIcon width={"14px"} height={"14px"} fill={"rgba(34,34,34,1)"}/>
            }
            <Text noOfLines={1} style={profileStyle}>{ellipsis(tradingPhotoCard.userProfile.nickname)}</Text>
          </Flex>
        }
        <Text style={priceStyle}>{isNull(tradingPhotoCard.price) ? t("tradingPhotoCardPage.offerPriceWithEmoji") : tradingPhotoCard.price}</Text>
        <HStack spacing={0}>
          <Text style={requestedAtStyle}>{tradingPhotoCard.requestedAt}</Text>
          {
            !hiddenChatStat && !isEmpty(tradingPhotoCard.userProfile?.responseText) && (
              <>
              <Text style={requestedAtStyle} px={"3px"}>{`•`}</Text>
              <Text style={responseRateText}>{`${tradingPhotoCard.userProfile?.responseText}`}</Text>
              </>
            )
          }
        </HStack>
      </VStack>
      {/* <ExploreTradingPhotoCardBottomSheet
        artistId={tradingPhotoCard.artistId}
        tradingPhotoCard={tradingPhotoCard}
        isShowBottomSheet={isShowExploreTradingPhotoCardBottomSheet}
        setIsShowBottomSheet={setIsShowExploreTradingPhotoCardBottomSheet}
      />
       */}
       <ExploreTradingPhotoCardBottomDrawer
          isOpen={isShowExploreTradingPhotoCardBottomSheet}
          onClose={() => setIsShowExploreTradingPhotoCardBottomSheet(false)}
          artistId={tradingPhotoCard.artistId}
          tradingPhotoCard={tradingPhotoCard}
       />
    </>
  )
}
