import { GridItem, SimpleGrid, Stack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import React from "react";
import { Virtuoso } from "react-virtuoso";
import { getGridSize } from '../../../utils/photoCardSize';
import PhotoCard from "../../photocard/PhotoCard";

const GRID_TYPE = "lg"

export default function PhotoCardsByEventViewer({
  events,
  onClickedPhotoCard,
  endReached,
}) {

  // #region draw cards
  const drawCardsWithEventName = (e) => {
    if (e.photoCards <= 0) {
      return (<div key={e.eventId}></div>)
    } else {
      return(
        <Stack key={e.eventId} paddingBottom={"18px"} >
          <Wrap paddingTop={"10px"} paddingBottom={"4px"} align={"center"} marginLeft={"-1px"} spacing={0}>
            <WrapItem alignItems={"center"}>
              <Text fontSize={"20px"} marginRight={"4px"}>{e.name?.slice(0,2)}</Text>
              <Text fontSize={"13px"}>{e.name?.slice(2)}</Text>
            </WrapItem>
          </Wrap>
          <SimpleGrid columns={getGridSize(GRID_TYPE)} spacing={"4px"}>
            {(e.photoCards)?.map((photoCard) => {
              return (
                <GridItem key={photoCard.photoCardId}>
                  {
                    photoCard
                      && <PhotoCard
                        photoCard={photoCard}
                        onClick={() => onClickedPhotoCard(photoCard, e)}
                        />
                  }
                </GridItem>
              )
            })}
          </SimpleGrid>
        </Stack>
      )
    }
  };
  // #endregion

  return (
    <Virtuoso
      useWindowScroll
      style={{ height: '100%' }}
      endReached={endReached}
      overscan={480}
      data={events}
      itemContent={(_, e) => { return drawCardsWithEventName(e)}}
    />
  )
}
